import React from "react";
import { FormControl, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import theme from "../theme/theme";
//import { color } from "html2canvas/dist/types/css/types/color";

const TextInput = ({
  name,
  label,
  value,
  onChange,
  error,
  variant,
  helperText,
  type,
  width,
  startAdornment,
  passwordIcon,
  passwordVisibility,
  mouseDown,
  click,
  disabled,
  inputRef,
}) => {
  return (
    <FormControl className={"TextInput"} sx={{width:"100%"}} >
      <TextField fullWidth
        type={type}
        value={value}
        name={name}
        disabled={disabled}
        onChange={onChange}
        variant={variant}
        error={error}
        helperText={error && helperText}
        label={label}
        inputRef={inputRef ? inputRef : null}
        InputProps={{
          startAdornment: startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ),
          endAdornment: passwordIcon && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={click}
                onMouseDown={mouseDown}
                edge="end"
              >
                {passwordVisibility ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

export const TextInputFont = ({
  name,
  label,
  value,
  onChange,
  error,
  variant,
  helperText,
  type,
  width,
  endAdornment,
  startAdornment,
  passwordIcon,
  passwordVisibility,
  mouseDown,
  click,
  inputRef,
  disabled=false
}) => {
  
  return (
    <FormControl    className="TextInputFont"  >
      <TextField fullWidth
      disabled={disabled}
        type={type}
        value={value}
        name={name}
        onChange={onChange}
        variant={variant}
        error={error}
        helperText={error && helperText}
        label={label}
        inputRef={inputRef ? inputRef : null}
        className=""
        InputProps={{
          
          startAdornment: startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ),
          endAdornment: endAdornment && (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ),
          
        }}
      />
    </FormControl>
  );
};

export default TextInput; 
