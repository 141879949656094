import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#5c26f8",
    },
    secondary: {
      main: "#d8d7f5",
    },
    thirdColor: {
      main: "#f6f8fe",
    },
    light: {
      main: "#c1c7d9",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    h1: {
      fontSize: "2.5rem",
      fontWeight: 600,
      marginBottom: "1rem",
    },
    h2: {
      fontSize: "2rem", // Define heading 2 size
      fontWeight: 500, // Slightly bold
      marginBottom: "0.8rem",
    },
    label1:{
      fontSize:"inherit"
    }
  },
  spacing: 8,
  shape: {
    borderRadius: 8,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: "10px", // Set the width of the scrollbar
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "red", // Set the color of the scrollbar thumb
          borderRadius: "10px", // Set the border radius for the scrollbar thumb
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "capitalize",
      },
    },
    MuiTextField: {
      root: {
        "& .MuiInputBase-input": {
          color: "#5c26f8", // Set the color when the TextField is not focused
        },
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          "*": {
            boxSizing: "border-box",
          },
          p: {
            fontSize: "16px",
            lineHeight: "1.5",
            fontWeight: "normal",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          width: "100%",
          marginTop: "1rem",
          marginBottom: "1rem",
          // "&.MuiInput-standard": {},
        },
      },
    },
  },
});

export default theme;
