import { fExecuteAPI } from "../Server/Apis";
import { fAlertToast } from "../Utility/Utility";

const getAttributes = async (xObj) => {
  try {
    const data = await fExecuteAPI("POST_API_WEB_ATTRIBUTES", xObj);
    console.log("asdasd",data);
    return data;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};
const getGoogleReviewUrl = async () => {
  try {
    const data = await fExecuteAPI("POST_API_GOOGLE_REVIEW_URL");
 
    return data;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};
const postCustomerCode = async (xObj) => {
  try {
    const data = await fExecuteAPI("POST_API_WEB_CUSTOMER_CODE", xObj);
    return data;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};
const postCustomerDetails = async (xObj) => {
  try {
    const data = await fExecuteAPI("POST_API_WEB_CUSTOMER_DETAILS", xObj);
    return data;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};

export { getAttributes, postCustomerCode, postCustomerDetails ,getGoogleReviewUrl};
