import React from "react";
import {
    
    Typography,
  } from "@mui/material";
import theme from "../theme/theme";

const HeadingText = ({ 
  label="",
  
}) => {
  return (
    <Typography variant="h5"
    textAlign={"center"}
    color={theme.palette.primary.main}
    sx={{ textDecoration: "underline" }} >{label}</Typography>
  );
};
 

export default HeadingText; 