import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import theme from "../../theme/theme";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import HeadingText from "../../components/headingText";
import ButtonComp from "../../components/button";
import   { useEffect, useState } from "react";
import { getGoogleReviewUrl } from "../action";
import { useParams } from "react-router-dom"; 
import { fAlertToast } from "../../Utility/Utility";
const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  // style: {
  //   display: "flex",
  //   flexDirection: "column",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   margin: "auto",
  //   borderRadius: 0.5,
  // },
  cardActions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    fontSize: "12px",
    //padding: 2,
  },
}));

const Landing = () => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const [GoogleReview, setGoogleReview] = useState([]);
  const params = useParams(); 
  let Location = params.Loc || "";
  const handleRoute = (route) => {
    if (route) {
      navigate(route);
    }
  };
  const onGoogleReview =async () => {
   
    let data=GoogleReview.find(review => review.Uid === Location);
    console.log({
      Location,
      data,
      asd:data?.GoogleReviewURL
    });
    if (!!data) {
      // navigate(data.GoogleReviewURL);
      window.open(data.GoogleReviewURL, '_blank');
    } else {
      fAlertToast("FAILED","Url setup is missing ,Contact gravity")
    }
  };

  const onLoad =async()=>{
    let data=await getGoogleReviewUrl()
    if (data.status ===200) {
      setGoogleReview(data.data.ResultSet)
      
    }
  }

  useEffect(() => {
    onLoad(); 
  }, []);
  return (
    <Box className={classes.container}>
      <Container maxWidth="sm">
        <Grid container alignItems="stretch">
          <Grid item xs={12}>
            <Card className="landingCard">              
              <CardActions className={classes.cardActions}>
                <Stack spacing={4}>
                  <ButtonComp
                    style={{
                      width: "160px",
                      textTransform: "capitalize",
                    }}
                    name={"Google Review"}
                    color={"primary"}
                    type="submit"
                    size="small"
                    variant="contained"
                    clickEvent={onGoogleReview}
                  />
                  <ButtonComp
                    style={{
                      width: "160px",
                      textTransform: "capitalize",
                    }}
                    name={"Loyalty"}
                    color={"primary"}
                    type="submit"
                    size="small"
                    variant="contained"
                    clickEvent={() => handleRoute("/customer-page")}
                  />
                </Stack>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Landing;
