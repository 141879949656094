import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { IconButton } from "@material-ui/core";
import MenuIcon from "@mui/icons-material/Menu";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
//images
import logo from "../../../assets/images/logo-1.png";
import onlyLogo from "../../../assets/images/gravity_small_logo.png";
import theme from "../../../theme/theme";
// redux
import { useDispatch, useSelector } from "react-redux";
// react router
import { useNavigate } from "react-router";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Logout } from "../../../pages/login/slice";

const Header = ({ hanldeClick, showIcon, screenSize }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuItem = useSelector((state) => state?.loginSlice?.menuItem);

  const handleRoutes = async (type, route) => {
    if (type === "INSTA") {
      navigate(route);
    } else if (type === "LOGOUT") {
      navigate(route);
      dispatch(Logout());
    }
  };
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        className="header"
        sx={{ backgroundColor: theme.palette.thirdColor.main }}
      >
        <Toolbar>
          {showIcon && (
            <IconButton
              size="large"
              edge="start"
              color="primary"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={hanldeClick}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Typography component="div">
            <div className="gravity-logo-container">
              <img
                src={screenSize <= 600 ? onlyLogo : logo}
                alt="gravity_logo"
                className="logo"
                onClick={() => {
                  navigate("/home");
                }}
              />
            </div>
          </Typography>
          <h2
            style={{
              flexGrow: 1,
              textAlign: "center",
              color: theme.palette.primary.main,
            }}
            className="center-txt"
          >
            GRAVITY RP MIS
          </h2>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {menuItem?.map((value) => (
                <MenuItem
                  key={value?.name}
                  onClick={() => handleRoutes(value?.type, value?.route)}
                  disabled={value?.disabled}
                >
                  <Typography textAlign="center">{value?.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
