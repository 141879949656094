import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  //Typography,
} from "@mui/material";

const SelectField = ({
  name,
  value,
  onChange,
  error,
  helperText,
  label,
  xData = [],
  variant = "standard",
  selectLabel = "",
}) => {
  return (
    // <Select
    //                     name={name}
    //                     value={value}
    //                     onChange={onChange}
    //                     error={error}
    //                     helperText={helperText}
    //                     variant="standard"
    //                     fullWidth
    //                   > {
    //                     xData.length > 0 ?
    //                         xData?.map( ( e,i ) =>
    //                         {
    //                             const keys = Object.keys( e );
    //                             return (
    //                                 <MenuItem key={ "Key" + i }
    //                                     value={ e[ keys[ 0 ] ] }

    //                                 >{ e[ keys[ 1 ] ] }</MenuItem>
    //                             );
    //                         } )
    //                         :
    //                         <MenuItem value={ "" }>none</MenuItem>
    //                 }
    //     </Select>
    <FormControl fullWidth className={"selectFormContorl"}>
      <InputLabel sx={{ left: "-14px" }}>{selectLabel}</InputLabel>
      <Select
        name={name}
        value={value}
        onChange={onChange}
        error={error}
        label={label}
        helperText={helperText}
        variant={variant}
        fullWidth
      >
        {xData.length > 0 ? (
          xData?.map((e, i) => {
            const keys = Object.keys(e);
            return (
              <MenuItem key={"Key" + i} value={e[keys[0]]}>
                {e[keys[1]]}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem value={""}>none</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default SelectField;
