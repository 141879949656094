import {
  Box,
  Button,
  Card,
  CardActions,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import theme from "../../theme/theme";
//import { makeStyles, useTheme } from "@mui/styles";
import { CardContent } from "@material-ui/core";
import BadgeIcon from "@mui/icons-material/Badge";
import PersonIcon from "@mui/icons-material/Person";
import TextInput from "../../components/input";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";
import { getAttributes, postCustomerDetails } from "../action";
import { fAlertToast } from "../../Utility/Utility";
import TypographyLabel from "../../components/typographyLabel";
import RadioButton from "../../components/radioButton";
import SelectField from "../../components/selectField";
import { Navigate } from "react-router-dom";
import HeadingText from "../../components/headingText";
import ButtonComp from "../../components/button";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    justifyContent: "center",
  },
  style: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // alignItems: "center",
    margin: "auto",
    backgroundColor: "white",
    padding: "6px",
    borderRadius: 5,
    fontSize: "12px",
    height: "99vh",
  },
}));

const CustomerDetails = ({ setFormData, formData }) => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();

  const vMediaQuery = useMediaQuery("(max-width:650px)");

  // const [formData, setFormData] = useState({
  //   customerCode: "",
  //   firstName: "",
  //   lastName: "",
  //   gender: "",
  //   nationality: "",
  //   mobile: "",
  //   email: "",
  //   type: "",
  // });

  const [formErrors, setFormErrors] = useState({
    customerCode: "",
    firstName: "",
    lastName: "",
    gender: "",
    nationality: "",
    mobile: "",
    email: "",
    type: "",
    refNo: "",
  });

  const [country, setCountry] = useState([]);
  const [type, setType] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...formErrors };

    // Validate customercode
    if (!formData?.customerCode) {
      newErrors.customerCode = "Customer code is required";
      valid = false;
    } else {
      newErrors.customerCode = "";
    }

    // Validate firstName
    if (!formData.firstName.trim()) {
      newErrors.firstName = "First name is required is required";
      valid = false;
    } else {
      newErrors.firstName = "";
    }

    // validate lastName
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last name is required";
      valid = false;
    } else {
      newErrors.lastName = "";
    }

    //validate gender
    if (!formData.gender.trim()) {
      newErrors.gender = "gender is required";
      valid = false;
    } else {
      newErrors.gender = "";
    }
    // validate nationality
    if (!formData.nationality.trim()) {
      newErrors.nationality = "Nationality is required";
      valid = false;
    } else {
      newErrors.nationality = "";
    }

    //validate mobile number
    if (!formData.mobile.trim()) {
      newErrors.mobile = "Mobile number is required";
      valid = false;
    } else {
      newErrors.firstName = "";
    }

    //validate email
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      valid = false;
    } else {
      newErrors.email = "";
    }

    //validate type
    if (!formData.type.trim()) {
      newErrors.type = "Type is required";
      valid = false;
    } else {
      newErrors.type = "";
    }

    setFormErrors(newErrors);
    return valid;
  };

  const getAttributesData = async () => {
    const data = await getAttributes();
    //console.log(data);
    setCountry(data.data.ResultSet?.Country);
    setType(data.data.ResultSet?.Type);
  };

  useEffect(() => {}, [type, country]);
  useEffect(() => {
    getAttributesData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Perform form submission logic here
      let obj = {
        UId: formData.UId,
        FName: formData.firstName,
        LName: formData.lastName,
        Gender: formData.gender,
        Country: formData.nationality,
        Mobile: formData.mobile,
        Email: formData.email,
        UserId: formData.customerCode,
        SPLGRP: formData.type,
        Cashier: formData.refNo,
      };
      // postCustomerDetails
      const data = await postCustomerDetails(obj);
      if (data.status == 200) {
        navigate("/success-page");
      }
    }
  };

  return (
    <Box
      className={classes.container}
      style={{
        height: vMediaQuery ? "100vh" : "100vh",
        alignItems: vMediaQuery ? "unset" : "center",
        width: "100%",
      }}
    >
      <form
        autoComplete="off"
        style={{ padding: "10px", width: vMediaQuery ? "100%" : "600px" }}
      >
        <Grid container className={classes.style}>
          <Grid item xs={12}>
            <HeadingText label={"Profile"} />
            <TextInput
              disabled={true}
              name={"customerCode"}
              type={"text"}
              label={"Customer Code"}
              variant="standard"
              value={formData.customerCode}
              onChange={handleChange}
              error={!!formErrors.customerCode}
              helperText={formErrors.customerCode}
              endAdornment={<BadgeIcon />}
            />

            <TextInput
              name={"firstName"}
              type={"text"}
              label={"First Name"}
              variant="standard"
              value={formData.firstName}
              onChange={handleChange}
              error={!!formErrors.firstName}
              helperText={formErrors.firstName}
              endAdornment={<PersonIcon />}
            />

            <TextInput
              name={"lastName"}
              type={"text"}
              label={"Last Name"}
              variant="standard"
              value={formData.lastName}
              onChange={handleChange}
              error={!!formErrors.lastName}
              helperText={formErrors.lastName}
              endAdornment={<PersonIcon />}
            />
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "0.6375em",
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              Gender
            </Typography>
            <div
              labelName={"Gender"}
              style={{
                display: "flex",
                justifyContent: "between",
                alignItems: "center",
              }}
            >
              <RadioButton
                value={"Female"}
                control={<Radio />}
                labelName={"Female"}
                label={"Female"}
                name={"gender"}
                checked={formData.gender === "Female"}
                onChange={handleChange}
                //disabled={false}
              />
              <RadioButton
                value={"Male"}
                control={<Radio />}
                labelName={"Male"}
                label={"Male"}
                name={"gender"}
                checked={formData.gender === "Male"}
                onChange={handleChange}
              />{" "}
            </div>
            <SelectField
              selectLabel={"Nationality"}
              name={"nationality"}
              value={formData.nationality}
              onChange={handleChange}
              error={!!formErrors.nationality}
              helperText={formErrors.nationality}
              xData={country}
            ></SelectField>

            <TextInput
              name={"mobile"}
              type={"text"}
              label={"Mobile"}
              variant="standard"
              value={formData.mobile}
              onChange={handleChange}
              error={!!formErrors.mobile}
              helperText={formErrors.mobile}
              endAdornment={<BadgeIcon />}
            />

            <TextInput
              label={"Email"}
              name={"email"}
              type={"text"}
              variant="standard"
              value={formData.email}
              onChange={handleChange}
              error={!!formErrors.email}
              helperText={formErrors.email}
              endAdornment={<BadgeIcon />}
            />

            <SelectField
              name={"type"}
              selectLabel={"Type"}
              value={formData.type}
              onChange={handleChange}
              error={!!formErrors.type}
              helperText={formErrors.type}
              xData={type}
            />

            <TextInput
              name={"refNo"}
              type={"text"}
              label={"Reference Number"}
              variant="standard"
              value={formData.refNo}
              onChange={handleChange}
              error={!!formErrors.refNo}
              helperText={formErrors.refNo}
              endAdornment={<PersonIcon />}
            />
            <div style={{ textAlign: "center" }}>
              <ButtonComp
                style={{
                  width: "160px",
                  textTransform: "capitalize",
                }}
                name={"Save"}
                color={"primary"}
                type="submit"
                size="small"
                variant="contained"
                clickEvent={handleSubmit}
              />
            </div>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default CustomerDetails;
