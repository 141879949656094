import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customerCount: [],
  dailyStock: [],
  departmentWiseSales: [],
  branchWiseSales: [],
  dateWisePurchaseVSales: [],
  dateWiseSales: [],
  hourlySales: [],
  posCustomerCount: [],
  salesLoss: [],
  stock: [],
  currentYearBranch: [],
  lastFiveYear: [],
  currentMonthWise: [],
  activeChartSection: [],
  activeChartSectionTitle: "",
  activeChartSectionColumns: [],
  activeChartSectionFooter: "",
  activeChartSectionDownloadableArray: [],
  isSubmitted: true,
  branchWiseAnalyticsData: {},
  departmentWiseAnalyticsData: {},
  salesVSCogsWiseAnalyticsData: {},
  salesVSPurchaseWiseAnalyticsData: {},
  stockAnalyticsData: {},
  salesLossAnalyticalData: {},
  customerCountAnalyticsData: {},
  inventoryAnalyticsData: [],
  HourAnalyticsData: {},
  currentYearProfitLossAnalytics: {},
  lastFiveYearProfitLossAnalytics: {},
  currentYearBranchProfitLossAnalytics: {},
  branchWiseStock: [],
  departmentWiseStock: [],
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,

  reducers: {
    setCustomerCount: (state, action) => {
      state.customerCount = action.payload;
    },
    setDailyStock: (state, action) => {
      state.dailyStock = action.payload;
    },
    setDateWisePurchaseVSales: (state, action) => {
      state.dateWisePurchaseVSales = action.payload;
    },
    setDateWiseSales: (state, action) => {
      state.dateWiseSales = action.payload;
    },
    setHourlySales: (state, action) => {
      state.hourlySales = action.payload;
    },
    setPosCustomerCount: (state, action) => {
      state.posCustomerCount = action.payload;
    },
    setSalesLoss: (state, action) => {
      state.salesLoss = action.payload;
    },
    setStock: (state, action) => {
      state.stock = action.payload;
    },

    setDepartmentWiseSales: (state, action) => {
      state.departmentWiseSales = action.payload;
    },
    setBranchWiseSales: (state, action) => {
      state.branchWiseSales = action.payload;
    },
    setActiveChartSection: (state, action) => {
      state.activeChartSection = action.payload;
    },
    setActiveChartSectionTitle: (state, action) => {
      state.activeChartSectionTitle = action.payload;
    },
    setActiveChartSectionDownloadableArray: (state, action) => {
      state.activeChartSectionDownloadableArray = action.payload;
    },
    setSubmit: (state, action) => {
      state.isSubmitted = action.payload;
    },

    setBranchWiseAnalyticsData: (state, action) => {
      state.branchWiseAnalyticsData = action.payload;
    },
    setDepartmentWiseAnalyticsData: (state, action) => {
      state.departmentWiseAnalyticsData = action.payload;
    },
    setSalesVSCogsWiseAnalyticsData: (state, action) => {
      state.salesVSCogsWiseAnalyticsData = action.payload;
    },
    setSalesVSPurchaseWiseAnalyticsData: (state, action) => {
      state.salesVSPurchaseWiseAnalyticsData = action.payload;
    },
    setStockAnalyticsData: (state, action) => {
      state.stockAnalyticsData = action.payload;
    },
    setSalesLossAnalyticalData: (state, action) => {
      state.salesLossAnalyticalData = action.payload;
    },
    setCustomerCountAnalyticsData: (state, action) => {
      state.customerCountAnalyticsData = action.payload;
    },
    setInventoryAnalyticsData: (state, action) => {
      state.inventoryAnalyticsData = action.payload;
    },
    setActiveChartColumns: (state, action) => {
      state.activeChartSectionColumns = action.payload;
    },
    setActiveChartSectionFooter: (state, action) => {
      state.activeChartSectionFooter = action.payload;
    },
    seHourAnalytics: (state, action) => {
      state.HourAnalyticsData = action.payload;
    },
    setBranchAnalytics: (state, action) => {
      state.branchWiseAnalyticsData = action.payload;
    },
    setDepartmentAnalytics: (state, action) => {
      state.departmentWiseAnalyticsData = action.payload;
    },
    setCurrentMonthWise: (state, action) => {
      state.currentMonthWise = action.payload;
    },
    setLastFiveYear: (state, action) => {
      state.lastFiveYear = action.payload;
    },
    SetCurrentYearBranch: (state, action) => {
      state.currentYearBranch = action.payload;
    },
    setCurrentYearProfitLossAnalytics: (state, action) => {
      state.currentYearProfitLossAnalytics = action.payload;
    },
    setLastFiveYearProfitLossAnalytics: (state, action) => {
      state.lastFiveYearProfitLossAnalytics = action.payload;
    },
    setCurrentYearBranchProfitLossAnalytics: (state, action) => {
      state.currentYearBranchProfitLossAnalytics = action.payload;
    },
    // Branchwise Stock
    setBranchWiseStock: (state, action) => {
      state.branchWiseStock = action.payload;
    },

    setDepartmentWiseStock: (state, action) => {
      state.departmentWiseStock = action.payload;
    },

    resetState: () => initialState,
  },
});

export default dashboardSlice.reducer;

export const {
  setStock,
  setSalesLoss,
  setPosCustomerCount,
  setHourlySales,
  setDateWiseSales,
  setDateWisePurchaseVSales,
  setDailyStock,
  setCustomerCount,
  setDepartmentWiseSales,
  setBranchWiseSales,
  setActiveChartSection,
  setActiveChartSectionTitle,
  setActiveChartSectionDownloadableArray,
  setSubmit,
  setCustomerCountAnalyticsData,
  setSalesLossAnalyticalData,
  setStockAnalyticsData,
  setSalesVSPurchaseWiseAnalyticsData,
  setSalesVSCogsWiseAnalyticsData,
  setDepartmentWiseAnalyticsData,
  setBranchWiseAnalyticsData,
  setInventoryAnalyticsData,
  setActiveChartColumns,
  setActiveChartSectionFooter,
  seHourAnalytics,
  resetState,
  setBranchAnalytics,
  setDepartmentAnalytics,
  setCurrentMonthWise,
  setLastFiveYear,
  SetCurrentYearBranch,
  setCurrentYearProfitLossAnalytics,
  setLastFiveYearProfitLossAnalytics,
  setCurrentYearBranchProfitLossAnalytics,
  setBranchWiseStock,
  setDepartmentWiseStock
} = dashboardSlice.actions;
