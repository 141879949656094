import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  from: moment(),
  to: moment(),
  location: [],
  selectedLocation: [],
  active: "today",
};
function getDateRange(filter) {
  const today = moment();
  let from, to;

  switch (filter) {
    case "today":
      from = today;
      to = today;
      break;
    case "yesterday":
      from = today.clone().subtract(1, "days");
      to = today.clone().subtract(1, "days");;
      break;
    case "currentWeek":
      from = today.clone().startOf("week");
      to = today;
      break;
    case "currentMonth":
      from = today.clone().startOf("month");
      to = today;
      break;
    case "currentYear":
      from = today.clone().startOf("year");
      to = today;
      break;
    case "last6days":
      from = today.clone().subtract(6, "days");
      to = today;
      break;
    case "last6month":
      from = today.clone().subtract(6, "months").startOf("month");
      to = today;
      break;
    case "last12month":
      from = today.clone().subtract(12, "months").startOf("month");
      to = today;
      break;
    default:
      from = today;
      to = today;
      break;
  }

  return {
    from: from,
    to: to,
  };
}
const filterSlice = createSlice({
  name: "filterSlice",
  initialState,
  reducers: {
    setLocation: (state, action) => {
      return { ...state, location: [...action.payload] };
    },
    setSelectedLocation: (state, action) => {
      return { ...state, selectedLocation: action.payload };
    },
    setActive: (state, action) => {
      const { from, to } = getDateRange(action.payload);

      return { ...state, active: action.payload, from: from, to: to };
    },
    setFromDate: (state, action) => {
      return { ...state, from: action.payload };
    },
    setToDate: (state, action) => {
      return { ...state, to: action.payload };
    },
  },
});

export default filterSlice.reducer;

export const {
  setLocation,
  setActive,
  setSelectedLocation,
  setToDate,
  setFromDate,
} = filterSlice.actions;
