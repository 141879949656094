import { fExecuteAPI } from "../../Server/Apis";
import { fAlertToast } from "../../Utility/Utility";

const getLogin = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI("GET_API_LOGIN", xObj);
    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};
const getWebStock = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI("POST_API_WEB_STOCK", xObj);
    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};
const getWebSalesLoss = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI("POST_API_WEB_SALES_LOSS", xObj);
    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};
const getPosCustomerCount = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI("POST_API_WEB_POS_CUSTOMER_COUNT", xObj);
    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};
const getHourlySales = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI("POST_API_WEB_HOURLY_SALES", xObj);
    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};
const getDepartmentWiseSales = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI(
      "POST_API_WEB_DEPARTMENT_WISE_SALES",
      xObj
    );
    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};
const getBranchWiseSales = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI("POST_API_WEB_BRANCH_WISE_SALES", xObj);

    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};
const getDateWiseSales = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI("POST_API_WEB_DATE_WISE_SALES", xObj);
    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};
const getDateWisePurchaseVSales = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI(
      "POST_API_WEB_DATE_WISE_PURCHASE_VS_SALES",
      xObj
    );
    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};
const getDailyStock = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI("POST_API_WEB_DAILY_REPORT", xObj);
    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};
const getCustomerCount = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI("POST_API_WEB_CUSTOMER_COUNT", xObj);
    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};
const getCurrentYearBranchWise = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI(
      "POST_API_WEB_PL_CURRENT_YEAR_BRANCH_WISE",
      xObj
    );
    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};
const getLastFiveYear = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI("POST_API_WEB_PL_LAST_FIVE_YEAR", xObj);
    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};
const getCurrentMonthWise = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI(
      "POST_API_WEB_CURRENT_YEAR_MONTH_WISE",
      xObj
    );
    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};

// Branchwise Stock 
const getBranchwiseStock = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI(
      "POST_API_WEB_BRANCH_WISE_STOCK",
      xObj
    );
    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};

const getDepartmentWiseStock = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI(
      "POST_API_WEB_DEPARTMENT_WISE_STOCK",
      xObj
    );
    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};


// *********************************  Calculation  start here******************************
const calculateValue = (array, columnName, operation) => {
  if (!Array.isArray(array)) {
    return;
  }

  if (typeof columnName !== "string") {
    return;
  }

  if (
    operation !== "sum" &&
    operation !== "average" &&
    operation !== "min" &&
    operation !== "max"
  ) {
    return;
  }

  let result = 0;

  if (operation === "sum") {
    result = array?.reduce((total, item) => total + (item[columnName] || 0), 0);
  } else if (operation === "average") {
    const sum = array?.reduce(
      (total, item) => total + (item[columnName] || 0),
      0
    );
    result = sum / array.length;
  } else if (operation === "min") {
    const minValues = array.map((item) => item[columnName] || 0);
    result = Math.min(...minValues);
  } else if (operation === "max") {
    const maxValues = array.map((item) => item[columnName] || 0);
    result = Math.max(...maxValues);
  }

  return result;
};

const performOperation = (value1, value2, operation) => {
  if (typeof value1 !== "number" || typeof value2 !== "number") {
    return "Invalid input, both values should be numbers";
  }

  switch (operation) {
    case "subtract":
      return value1 - value2;
    case "percentage":
      return (value1 / value2) * 100;
    case "average":
      return (value1 + value2) / 2;
    case "ratio":
      return value1 / value2;
    default:
      return "Invalid operation";
  }
};
//stock chart
const getStockChartAnalyticsData = (data) => {
  const min = calculateValue(data, "StockVal", "min");
  const max = calculateValue(data, "StockVal", "max");
  const avg = calculateValue(data, "StockVal", "average");
  return {
    min: Number(min).toLocaleString(),
    max: Number(max).toLocaleString(),
    avg: Number(avg).toLocaleString(),
  };
};

//invoice count
const getCustomerCountAnalyticsData = (data) => {
  const invoiceCount = calculateValue(data, "CustCount", "sum");
  const averageCount = calculateValue(data, "CustCount", "average");
  const basketSum = calculateValue(data, "Amt", "sum");
  const basketValue = performOperation(basketSum, averageCount, "ratio");
  return {
    invoiceCount: Number(invoiceCount).toFixed().toLocaleString(),
    averageCount: Number(averageCount).toFixed().toLocaleString(),
    basketSum: Number(basketSum).toFixed().toLocaleString(),
    basketValue: Number(basketValue).toLocaleString(),
  };
};

///sales vs cogs
const getDateWiseAnalyticsData = (data) => {
  const sales = calculateValue(data, "DtLandVal", "sum");
  const cogs = calculateValue(data, "DtAvgVal", "sum");
  const gp = performOperation(sales, cogs, "subtract");
  const gpPercentage = performOperation(gp, sales, "percentage");
  return {
    sales: Number(sales).toFixed().toLocaleString(),
    cogs: Number(cogs).toFixed().toLocaleString(),
    gp: Number(gp).toFixed().toLocaleString(),
    gpPercentage: Number(gpPercentage).toFixed(2),
  };
};
const getBranchWiseData = (data) => {
  const sales = calculateValue(data, "BrSales", "sum");
  const cogs = calculateValue(data, "BrCOGS", "sum");
  const gp = performOperation(sales, cogs, "subtract");
  const gpPercentage = performOperation(gp, sales, "percentage");
  return {
    sales: Number(sales).toFixed().toLocaleString(),
    cogs: Number(cogs).toFixed().toLocaleString(),
    gp: Number(gp).toFixed().toLocaleString(),
    gpPercentage: Number(gpPercentage).toFixed(2),
  };
};
const getDepartmentData = (data) => {
  const sales = calculateValue(data, "Sales", "sum");
  const cogs = calculateValue(data, "COGS", "sum");
  const gp = performOperation(sales, cogs, "subtract");
  const gpPercentage = performOperation(gp, sales, "percentage");
  return {
    sales: Number(sales).toFixed().toLocaleString(),
    cogs: Number(cogs).toFixed().toLocaleString(),
    gp: Number(gp).toFixed().toLocaleString(),
    gpPercentage: Number(gpPercentage).toFixed(2),
  };
};

//sales vs purchases
const getSalesVsPurchase = (data) => {
  const sales = calculateValue(data, "PSAvgVal", "sum");
  const purchase = calculateValue(data, "PSTotalPurchase", "sum");
  const diff = performOperation(purchase, sales, "subtract");
  const ratio = performOperation(purchase, sales, "ratio");
  return {
    sales: Number(sales).toFixed().toLocaleString(),
    purchase: Number(purchase).toFixed().toLocaleString(),
    diff: Number(diff).toFixed().toLocaleString(),
    ratio: Number(ratio).toFixed(2),
  };
};

//loss
const getLossData = (data) => {
  const sales = calculateValue(data, "SalLossAmt", "sum");
  const cogs = calculateValue(data, "SalLossCOGS", "sum");
  const gp = performOperation(sales, cogs, "subtract");
  const gpPercentage = performOperation(cogs, sales, "percentage");
  return {
    sales: Number(sales).toFixed().toLocaleString(),
    cogs: Number(cogs).toFixed().toLocaleString(),
    gp: Number(gp).toFixed().toLocaleString(),
    gpPercentage: Number(gpPercentage).toFixed(),
  };
};

//inventory

const getInventoryData = (data, stockData) => {
  const avgValSum = calculateValue(data, "DtAvgVal", "sum");
  const stockValSum = stockData[0]?.StockVal;
  const count = data.length ? data.length : 0;
  const velocity = Number(avgValSum) / Number(count);
  const projection = Number(stockValSum) / Number(velocity);

  return [
    { name: "Stock Value", value: parseInt(stockValSum).toLocaleString() },
    { name: "Velocity", value: parseInt(velocity).toLocaleString() },
    {
      name: "Projection",
      value: parseInt(projection).toLocaleString(),
    },
  ];
};
//hour
const getHourData = (data) => {
  const AmtLc = calculateValue(data, "AmtLc", "sum");
  const Count = calculateValue(data, "Count", "sum");

  return { Count: Count.toLocaleString(), AmtLc: AmtLc.toLocaleString() };
};

const getYearlyProfitLoss = (data) => {
  const Income = calculateValue(data, "Income", "sum");
  const Expense = calculateValue(data, "Expense", "sum");
  const Profit = calculateValue(data, "Profit", "sum");
  const percentage = (Profit / Income) * 100;

  return { Income, Expense, Profit ,percentage};
};

// *********************************  Calculation end here ******************************

export {
  getLogin,
  getWebStock,
  getWebSalesLoss,
  getPosCustomerCount,
  getHourlySales,
  getDepartmentWiseSales,
  getDateWiseSales,
  getDateWisePurchaseVSales,
  getDailyStock,
  getCustomerCount,
  getStockChartAnalyticsData,
  getCustomerCountAnalyticsData,
  getDateWiseAnalyticsData,
  getSalesVsPurchase,
  getLossData,
  getInventoryData,
  getBranchWiseSales,
  getHourData,
  getBranchWiseData,
  getDepartmentData,
  getCurrentMonthWise,
  getLastFiveYear,
  getCurrentYearBranchWise,
  getYearlyProfitLoss,
  getBranchwiseStock,
  getDepartmentWiseStock
};
