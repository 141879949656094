import React, { useRef, useState, useEffect } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import { Box, Grid, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Slider from "react-slick";
// card
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
// icons
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import BadgeIcon from "@mui/icons-material/Badge";
// components
import ButtonComp from "../../components/button";
import TextInput from "../../components/input";
// images
import analytics from "../../assets/images/analytics.jpg";
import discussion from "../../assets/images/discussion.jpg";
import statistics from "../../assets/images/statistics.jpg";
import logo from "../../assets/images/gravity_small_logo.png";
//redux
import { loginSuccess, setFeatureObj, setMenuItem } from "./slice";
import { setLocation, setSelectedLocation } from "../Filters/Slice";
import { useDispatch, useSelector } from "react-redux";
import { checkInstaAvailability, getLogin } from "./action";
// utility
import { fAlertToast } from "../../Utility/Utilitys";
// mui
import { makeStyles } from "@material-ui/core";
import theme from "../../theme/theme";
// router
import { useNavigate } from "react-router";
// styles
const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
}));

const Login = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const classes = useStyles();

  const [error, setError] = useState({
    clientId: false,
    userId: false,
    password: false,
  });
  const [formData, setFormData] = useState({
    clientId: "",
    userId: "",
    password: "",
    rememberMe: false,
  });
  const [message, setMessage] = useState({
    clientId: "",
    userId: "",
    password: "",
  });
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const userData = useSelector((state) => state?.loginSlice);
  const menuItem = useSelector((state) => state?.loginSlice?.menuItem);

  useEffect(() => {
    if (userData?.rememberMe) {
      setFormData({
        userId: userData?.userId,
        clientId: userData?.clientId,
        password: userData?.password,
        rememberMe: userData?.rememberMe
      });
    } else {
      setFormData({
        userId: "",
        clientId: "",
        password: "",
        rememberMe: false,
      });
    }
  }, [userData]);

  const sliderImages = [statistics, discussion, analytics];
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 4000, // Set autoplay interval to 4 seconds (4000 milliseconds)
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // const rememberMe = useSelector((state) => state?.loginSlice?.rememberMe);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (value.trim() === "") {
      setError(() => {
        return { ...error, [name]: true };
      });
      setMessage(() => {
        return { ...message, [name]: `${name} is required` };
      });
    } else {
      setError(() => {
        return { ...error, [name]: false };
      });
    }
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };
  const handleSubmit = async (e) => {
    const isFormValid =
      error.userId === false &&
      error.password === false &&
      error.clientId === false;
    if (isFormValid) {
      e.preventDefault();
      let { rememberMe, ...restObj } = formData;
      const data = await getLogin(restObj);
      if (data?.status === 200) {
        fAlertToast("SUCCESS", data?.data?.Type);
        const removed = removeSpace(data?.data?.ResultSet?.features).map(
          (item) => ({ [item.Feature]: item.Active === "Y" ? true : false })
        );
        const newObj = Object.assign({}, ...removed);

        dispatch(setFeatureObj(newObj));
        dispatch(
          loginSuccess({
            ...data?.data?.ResultSet,
            password: formData.password,
            rememberMe: formData?.rememberMe,
          })
        );
        dispatch(setLocation([...data?.data?.ResultSet?.loc]));
        dispatch(setSelectedLocation([...data?.data?.ResultSet?.loc]));
        dispatch(
          setMenuItem(
            checkInstaAvailability(data?.data?.ResultSet?.ecommlist, menuItem)
          )
        );
        navigate("/home");
      }
    } else {
      fAlertToast("WARNING", "All fields are required");
    }
  };

  const removeSpace = (arr) => {
    return arr.map((o) => {
      return Object.entries(o)
        .map(([key, value]) => [
          key.replace(/[\s\\/]+/g, ""),
          value.replace(/[\s\\/]+/g, ""),
        ])
        .reduce((result, [normalizedKey, value]) => {
          result[normalizedKey] =
            value && typeof value === "object" ? removeSpace(value) : value;
          return result;
        }, {});
    });
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFormData({
      userId: "",
      clientId: "",
      password: "",
      rememberMe: false,
    });
    inputRef.current.focus();
  };
  return (
    <Box className={classes.container}>
      <Container maxWidth="lg">
        <Grid container alignItems="stretch">
          <Grid item xs={12} sm={5}>
            <form
              autoComplete="off"
              onSubmit={handleSubmit}
              onReset={handleReset}
              style={{ height: "98.7%" }}
            >
              <Card className="login-card">
                <CardContent
                  sx={{ pb: 0, display: "flex", flexDirection: "column" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src={logo}
                      alt="gravity_logo"
                      style={{ width: "25% !important", marginRight: "8px" }}
                    />
                    <h1 style={{ margin: 0 }}>Log In</h1>
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <TextInput
                      width={"80%"}
                      name={"clientId"}
                      type={"text"}
                      label={"Client Id"}
                      variant="standard"
                      value={formData?.clientId}
                      onChange={handleChange}
                      error={Boolean(error?.clientId)}
                      helperText={message?.clientId}
                      startAdornment={<BadgeIcon />}
                      inputRef={inputRef}
                    />
                    <TextInput
                      width={"80%"}
                      name={"userId"}
                      type={"text"}
                      label={"User Id"}
                      variant="standard"
                      value={formData?.userId}
                      onChange={handleChange}
                      error={Boolean(error?.userId)}
                      helperText={message?.userId}
                      startAdornment={<PersonIcon />}
                    />
                    <TextInput
                      width={"80%"}
                      name={"password"}
                      type={showPassword ? "text" : "password"}
                      label={"Password"}
                      variant="standard"
                      value={formData?.password}
                      onChange={handleChange}
                      error={Boolean(error?.password)}
                      helperText={message?.password}
                      startAdornment={<LockIcon />}
                      passwordIcon={true}
                      passwordVisibility={showPassword}
                      click={handleClickShowPassword}
                      mouseDown={handleMouseDownPassword}
                    />
                    <Stack
                      width={"80%"}
                      flexWrap="wrap"
                      justifyContent="space-between"
                      direction="row"
                    >
                      <FormControlLabel
                        sx={{ ml: 0, color: theme.palette.primary.main }}
                        control={
                          <Checkbox
                            sx={{ marginLeft: "-12px" }}
                            checked={formData?.rememberMe}
                            onChange={handleCheckboxChange}
                            name={"rememberMe"}
                          />
                        }
                        label={
                          <span style={{ fontSize: "0.9rem" }}>
                            Remember Me
                          </span>
                        }
                      />
                      <ButtonComp
                        name={"Reset"}
                        color={"primary"}
                        type="reset"
                        style={{
                          color: theme.palette.primary.main,
                          textTransform: "capitalize",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
                <CardActions sx={{ marginTop: "auto" }}>
                  <ButtonComp
                    style={{
                      display: "block",
                      margin: "12px auto",
                      width: "140px",
                      textTransform: "capitalize",
                    }}
                    name={"log in"}
                    color={"primary"}
                    type="submit"
                    variant="contained"
                  />
                </CardActions>
              </Card>
            </form>
          </Grid>
          <Grid
            item
            sm={7}
            sx={{
              display: () => ({
                xs: "none",
                sm: "block",
              }),
            }}
          >
            <Slider {...settings}>
              {sliderImages &&
                sliderImages.map((item, index) => {
                  return (
                    <div key={index} className="image">
                      <img src={item} alt="Banner Section" />
                    </div>
                  );
                })}
            </Slider>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Login;
