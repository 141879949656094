import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import theme from "../theme/theme";
const RoundedCheckboxWithLabel = ({ onChange, checked, label }) => {
  // Custom SVG icons for unchecked and checked states
  const UncheckedIcon = (
    <svg width="20" height="20">
      <circle
        cx="10"
        cy="10"
        r="8"
        stroke="rgba(0, 0, 0, 0.54)"
        fill="transparent"
        strokeWidth="2"
      />
    </svg>
  );

  const CheckedIcon = (
    <svg width="20" height="20">
      <circle cx="10" cy="10" r="8" fill={theme.palette.primary.main} />
      <path d="M7 10L9 12L14 7" fill="white" />
    </svg>
  );

  return (
    <FormControlLabel
      style={{ height: "30px", color: "darkslategrey", fontSize: "14px" }}
      control={<Checkbox icon={UncheckedIcon} checkedIcon={CheckedIcon} />}
      label={<span style={{ fontSize: "14px" }}>{label}</span>}
      checked={checked}
      onChange={onChange}
    />
  );
};

export default RoundedCheckboxWithLabel;
