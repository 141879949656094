import { httpRequest, thirdPartyHttpRequest } from "./Axios";

export const GET_API_LOGIN = (obj) => {
  return {
    method: "POST",
    url: `misreport/Weblogin`,
    data: obj,
  };
};
export const POST_API_WEB_CUSTOMER_COUNT = (obj) => {
  return {
    method: "POST",
    url: `misreport/WebCustomerCount`,
    data: obj,
  };
};
export const POST_API_WEB_DAILY_REPORT = (obj) => {
  return {
    method: "POST",
    url: `misreport/WebdailyStock`,
    data: obj,
  };
};
export const POST_API_WEB_DATE_WISE_PURCHASE_VS_SALES = (obj) => {
  return {
    method: "POST",
    url: `misreport/webdatewisePurchaseVsSales`,
    data: obj,
  };
};
export const POST_API_WEB_DATE_WISE_SALES = (obj) => {
  return {
    method: "POST",
    url: `misreport/webdatewiseSales`,
    data: obj,
  };
};
export const POST_API_WEB_DEPARTMENT_WISE_SALES = (obj) => {
  return {
    method: "POST",
    url: `misreport/WebDepartmentwisesales`,
    data: obj,
  };
};
export const POST_API_WEB_BRANCH_WISE_SALES = (obj) => {
  return {
    method: "POST",
    url: `misreport/WebBranchwisesales`,
    data: obj,
  };
};
export const POST_API_WEB_HOURLY_SALES = (obj) => {
  return {
    method: "POST",
    url: `misreport/WebHourlySales`,
    data: obj,
  };
};
export const POST_API_WEB_POS_CUSTOMER_COUNT = (obj) => {
  return {
    method: "POST",
    url: `misreport/WebPOSCustomerCount`,
    data: obj,
  };
};
export const POST_API_WEB_SALES_LOSS = (obj) => {
  return {
    method: "POST",
    url: `misreport/websalesLoss`,
    data: obj,
  };
};
export const POST_API_WEB_STOCK = (obj) => {
  return {
    method: "POST",
    url: `misreport/webstock`,
    data: obj,
  };
};
export const POST_API_THIRD_PARTY_LOGIN = (obj) => {
  return {
    method: "POST",
    url: `auth/login`,
    data: obj,
  };
};
export const POST_API_WEB_INSTA_ITEM = (obj) => {
  return {
    method: "POST",
    url: `misreport/webinstaitems`,
    data: obj,
  };
};
export const POST_API_WEB_INSTA_CALC = (obj) => {
  return {
    method: "POST",
    url: `misreport/webinstacalc`,
    data: obj,
  };
};
export const POST_API_WEB_INSTA_SYNC = (obj) => {
  return {
    method: "POST",
    url: `misreport/instaproductupdate`,
    data: obj,
  };
};
export const POST_API_WEB_CUSTOMER_CODE = (obj) => {
  return {
    method: "POST",
    url: `Loyalty/loyaltySimplified`,
    data: obj,
  };
};
export const POST_API_WEB_CUSTOMER_DETAILS = (obj) => {
  return {
    method: "POST",
    url: `Loyalty/loyaltySimplifiedSave`,
    data: obj,
  };
};
export const POST_API_WEB_ATTRIBUTES = (obj) => {
  return {
    method: "POST",
    url: `Loyalty/loyaltAttribute`,
    data: obj,
  };
};
export const POST_API_GOOGLE_REVIEW_URL = (obj) => {
  return {
    method: "POST",
    url: `Loyalty/GoogleReviewLinks`,
    data: obj,
  };
};

export const POST_API_WEB_PL_CURRENT_YEAR_BRANCH_WISE = (obj) => {
  return {
    method: "POST",
    url: `misreport/webplcurrentyearbranchwise`,
    data: obj,
  };
};
export const POST_API_WEB_PL_LAST_FIVE_YEAR = (obj) => {
  return {
    method: "POST",
    url: `misreport/webpllastfiveyear`,
    data: obj,
  };
};
export const POST_API_WEB_CURRENT_YEAR_MONTH_WISE = (obj) => {
  return {
    method: "POST",
    url: `misreport/webplcurrentyearmonthwise`,
    data: obj,
  };
};

// Branchwise Stock 

export const POST_API_WEB_BRANCH_WISE_STOCK = (obj) => {
  return {
    method: "POST",
    url: `misreport/WebBranchwiseStock`,
    data: obj,
  };
};

export const POST_API_WEB_DEPARTMENT_WISE_STOCK = (obj) => {
  return {
    method: "POST",
    url: `misreport/WebDepartmentwiseStock`,
    data: obj,
  };
};

export const fExecuteAPI = async (xAPIName, apiObj = {}) => {
  try {
    var resp = {};
    switch (xAPIName) {
      case "GET_API_LOGIN": {
        resp = await httpRequest(GET_API_LOGIN(apiObj));
        break;
      }
      case "POST_API_WEB_CUSTOMER_COUNT": {
        resp = await httpRequest(POST_API_WEB_CUSTOMER_COUNT(apiObj));
        break;
      }
      case "POST_API_WEB_DAILY_REPORT": {
        resp = await httpRequest(POST_API_WEB_DAILY_REPORT(apiObj));
        break;
      }
      case "POST_API_WEB_DATE_WISE_PURCHASE_VS_SALES": {
        resp = await httpRequest(
          POST_API_WEB_DATE_WISE_PURCHASE_VS_SALES(apiObj)
        );
        break;
      }
      case "POST_API_WEB_DATE_WISE_SALES": {
        resp = await httpRequest(POST_API_WEB_DATE_WISE_SALES(apiObj));
        break;
      }
      case "POST_API_WEB_DEPARTMENT_WISE_SALES": {
        resp = await httpRequest(POST_API_WEB_DEPARTMENT_WISE_SALES(apiObj));
        break;
      }
      case "POST_API_WEB_HOURLY_SALES": {
        resp = await httpRequest(POST_API_WEB_HOURLY_SALES(apiObj));
        break;
      }
      case "POST_API_WEB_POS_CUSTOMER_COUNT": {
        resp = await httpRequest(POST_API_WEB_POS_CUSTOMER_COUNT(apiObj));
        break;
      }
      case "POST_API_WEB_SALES_LOSS": {
        resp = await httpRequest(POST_API_WEB_SALES_LOSS(apiObj));
        break;
      }
      case "POST_API_WEB_STOCK": {
        resp = await httpRequest(POST_API_WEB_STOCK(apiObj));
        break;
      }
      case "POST_API_WEB_BRANCH_WISE_SALES": {
        resp = await httpRequest(POST_API_WEB_BRANCH_WISE_SALES(apiObj));
        break;
      }
      case "POST_API_THIRD_PARTY_LOGIN": {
        resp = await thirdPartyHttpRequest(POST_API_THIRD_PARTY_LOGIN(apiObj));
        break;
      }
      case "POST_API_WEB_INSTA_ITEM": {
        resp = await httpRequest(POST_API_WEB_INSTA_ITEM(apiObj));
        break;
      }
      case "POST_API_WEB_INSTA_CALC": {
        resp = await httpRequest(POST_API_WEB_INSTA_CALC(apiObj));
        break;
      }
      case "POST_API_WEB_INSTA_SYNC": {
        resp = await httpRequest(POST_API_WEB_INSTA_SYNC(apiObj));
        break;
      }
      case "POST_API_WEB_CUSTOMER_CODE": {
        resp = await httpRequest(POST_API_WEB_CUSTOMER_CODE(apiObj));
        break;
      }
      case "POST_API_WEB_CUSTOMER_DETAILS": {
        resp = await httpRequest(POST_API_WEB_CUSTOMER_DETAILS(apiObj));
        break;
      }
      case "POST_API_WEB_ATTRIBUTES": {
        resp = await httpRequest(POST_API_WEB_ATTRIBUTES(apiObj));
        break;
      }
      case "POST_API_GOOGLE_REVIEW_URL": {
        resp = await httpRequest(POST_API_GOOGLE_REVIEW_URL(apiObj));
        break;
      }
      case "POST_API_WEB_CURRENT_YEAR_MONTH_WISE": {
        resp = await httpRequest(POST_API_WEB_CURRENT_YEAR_MONTH_WISE(apiObj));
        break;
      }
      case "POST_API_WEB_PL_LAST_FIVE_YEAR": {
        resp = await httpRequest(POST_API_WEB_PL_LAST_FIVE_YEAR(apiObj));
        break;
      }
      case "POST_API_WEB_PL_CURRENT_YEAR_BRANCH_WISE": {
        resp = await httpRequest(
          POST_API_WEB_PL_CURRENT_YEAR_BRANCH_WISE(apiObj)
        );
        break;
      }
      case "POST_API_WEB_BRANCH_WISE_STOCK": {
        resp = await httpRequest(
          POST_API_WEB_BRANCH_WISE_STOCK(apiObj)
        );
        break;
      }
      case "POST_API_WEB_DEPARTMENT_WISE_STOCK": {
        resp = await httpRequest(
          POST_API_WEB_DEPARTMENT_WISE_STOCK(apiObj)
        );
        break;
      }

      default:
        break;
    }
    if (resp?.status === 200) {
      return resp;
    }
  } catch (err) {
    return err;
  }
};
