import axios from "axios";
import { fAlertToast } from "../Utility/Utility";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  responseType: "json",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const httpRequest = async (axiosData) => {
  const loader = document.getElementById("loader");
  try {
    loader.style.display = "flex";
    const res = await axiosInstance.request(axiosData);
    loader.style.display = "none";

    if (res.status == 200) {
      return res;
    }
  } catch (error) {
    loader.style.display = "none";
    fAlertToast(
      "FAILED",
      error.response.data.message == undefined
        ? error.response.data.Status
        : error.response.data.message
    );
    throw error;
  }
};

const thirdPartyInstance = axios.create({
  baseURL: `${process.env.REACT_APP_THIRD_PARTY_BASE_URL}`,
  responseType: "json",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const thirdPartyHttpRequest = async (axiosData) => {
  const loader = document.getElementById("loader");
  try {
    loader.style.display = "flex";
    const res = await thirdPartyInstance.request(axiosData);
    loader.style.display = "none";

    if (res.status == 200) {
      return res;
    }
  } catch (error) {
    loader.style.display = "none";
    fAlertToast(
      "FAILED",
      error.response.data.message == undefined
        ? error.response.data.Status
        : error.response.data.message
    );
    throw error;
  }
};
