import React from "react";

//material ui
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { selectActiveButton, setActive } from "../Slice";

function FilterButton() {
  const dispatch = useDispatch();

  const activeButton = useSelector((state) => state?.filterSlice?.active);
  const handleButtonClick = (buttonName) => {
    dispatch(setActive(buttonName));
  };
  const filterButtonArray = [
    { label: "Today", name: "today" },
    { label: "Yesterday", name: "yesterday" },
    { label: "Current Week", name: "currentWeek" },
    { label: "Current Month", name: "currentMonth" },
    { label: "Current Year", name: "currentYear" },
    { label: "Last 60 days", name: "last6days" },
    { label: "Last 6 month", name: "last6month" },
    { label: "Last 12 month", name: "last12month" },
    { label: "Custom Date", name: "custom" },
  ]
  return (
    <div style={{ margin: " 16px 7px 5px 7px" }}>
      {/* <Typography variant="subtitle1">Filter By </Typography>{" "} */}
      <Stack
        direction={"row"}
        spacing={{ xs: 0.5, sm: 0.3 }}
        useFlexGap
        flexWrap="wrap"
      >
        {filterButtonArray.map((button, index) => (
          <Button
            key={index}
            size="small"
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              color: "darkslategrey", // Text color
              borderColor: "dimgray", // Border color
              "&:hover": {
                borderColor: "dimgray",
                backgroundColor: "#5c26f9",
                color: "white",
              },
              "&.MuiButton-contained": {
                backgroundColor: "primary",
                color: "white",
              },
            }}
            color={"primary"}
            variant={activeButton === button.name ? "contained" : "outlined"}
            onClick={() => handleButtonClick(button.name)}
          >
            <span>{button.label}</span>
          </Button>
        ))}
      </Stack>
    </div>
  );
}

export default FilterButton;
