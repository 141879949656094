import React from "react";
import {
    
    FormControlLabel,
    Radio,
    //Typography,
  } from "@mui/material";

const RadioButton = ({ 
    value,label,checked,onChange,xDisabled = false ,labelName,name

}) => {
  return (
    <FormControlLabel className={"radioButtonFont"}
            value={ value }
            control={ <Radio  sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: "inherit",
                },
              }} /> }
            label={ label }
            name={ name }
            checked={ checked }
            onChange={ onChange }
            disabled={ xDisabled }
            
        >
          {labelName}
          </FormControlLabel>
//     <FormControl>
//   <FormLabel >{RadioLabel}</FormLabel>
//   <RadioGroup
   
//   >
//     <FormControlLabel value="female" control={<Radio  sx={{
//                 '& .MuiSvgIcon-root': {
//                    fontSize: "inherit",
//                  },
//                }}/>} label="Female" />
//                label={ label }
//             name={ label }
//             checked={ checked }
//              onChange={ onChange }
//              disabled={ xDisabled }
    
//   </RadioGroup>
// </FormControl>
  );
};
 

export default RadioButton; 