import React, { useEffect } from "react";
import Header from "../../components/layout/header/header";
import { useDispatch, useSelector } from "react-redux";
import { AppBar, Box, Container, Toolbar } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme/theme";
import moment from "moment";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getWebInstaCalc, getWebInstaItems, getWebInstaSync } from "./action";
import {
  setSelectedCard,
  setSelectedCardIndex,
  setWebInstaItem,
} from "./slice";

//styles
const useStyles = makeStyles(() => ({
  card: {
    minWidth: "200px",
    padding: "20px",
    margin: "15px",
    padding: "20px",
    borderRadius: " 8px",
    backgroundColor: "#fff",
    boxShadow: " 0 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "box-shadow 0.3s ease-in-out",
    cursor: "pointer",
    fontSize: "12px",
  },
  selectedCard: {
    boxShadow: " 0 0 10px #5c26f8",
  },
  footer: {
    position: "fixed",
    border: "1px solid",
    bottom: "0",
    width: "100vw",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
}));

const InstaIntegration = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const size = useSelector((state) => state?.sidebarSlice?.screenSize);
  const instaLocation = useSelector((state) => state?.loginSlice?.instaloc);
  const clientId = useSelector((state) => state?.loginSlice?.clientId);
  const rows = useSelector(
    (state) => state?.instaIntegrationSlice?.webinstaitems
  );
  const selectedCard = useSelector(
    (state) => state?.instaIntegrationSlice?.selectedCard
  );
  const selectedCardIndex = useSelector(
    (state) => state?.instaIntegrationSlice?.selectedCardIndex
  );

  const handleCardClick = async (index) => {
    dispatch(setSelectedCardIndex(index));
    const selected = instaLocation[index];
    dispatch(setSelectedCard(index === selectedCard ? null : index));
    const data = await getWebInstaItems({
      clientId,
      loc: selected?.UId,
      apiKey: "",
      identifier: selected?.Insta_ID,
    });
    dispatch(setWebInstaItem(data?.data?.ResultSet));
  };
  const handleCalClick = async () => {
    const selected = instaLocation[selectedCardIndex];
    await getWebInstaCalc({
      clientId,
      loc: selected?.UId,
    });
    const data = await getWebInstaItems({
      clientId,
      loc: selected?.UId,
      apiKey: "",
      identifier: selected?.Insta_ID,
    });

    dispatch(setWebInstaItem(data?.data?.ResultSet));
  };
  const handleSyncClick = async () => {
    const products = rows && rows.length > 0 &&
      rows?.filter((val) => val?.status !== "not_matched")
        ?.map((item) => ({
          plu: item?.plu,
          barcode: item?.barcode,
          status: item?.status,
          price: item?.VATRSP,
          discountPrice: item?.PromoVATRSP,
        }));
    const dataArr = [];
    if (products && products.length > 0) {
      for (let i = 0; i < products.length; i += 1000) {
        const chunk = products.slice(i, i + 1000);
        const { data } = await sendApiRequests(chunk);
        dataArr.push(data?.ResultSet);
      }
    }
    const modifiedArr = dataArr.flat();
    const updatedRows = rows && rows.length > 0 && rows?.map((obj) => {
      const matchingVal = modifiedArr.find(
        (val) => obj?.barcode === val?.barcode
      );
      if (matchingVal) {
        return {
          ...obj,
          status: matchingVal.status,
        };
      } else {
        return obj;
      }
    });

    dispatch(setWebInstaItem(updatedRows));
  };
  const sendApiRequests = async (chunk) => {
    const selected = instaLocation[selectedCardIndex];
    const responses = await getWebInstaSync({
      clientId,
      loc: selected?.UId,
      apiKey: "",
      identifier: selected?.Insta_ID,
      products: chunk.length > 0 ? chunk : [],
    });
    return responses;
  };

  const columns = [
    { field: "barcode", headerName: "Barcode", width: 160 },
    {
      field: "ItemCode",
      headerName: "Item Code",
      width: 130,
    },
    {
      field: "ItemName",
      headerName: "Item Name",
      width: 280,
    },
    {
      field: "UnitName",
      headerName: "Unit ",
      width: 20,
    },
    {
      field: "RSP",
      headerName: "RSP",
      width: 140,
      headerAlign: "right",
      align: "right",
    },

    {
      field: "VATRSP",
      headerName: "VATRSP",
      width: 80,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "SyncStatus",
      headerName: "SyncStatus",
      width: 120,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "PromoRSP",
      headerName: "PromoRSP",
      width: 90,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "PromoVATRSP",
      headerName: "PromoVATRSP",
      width: 130,
      headerAlign: "right",
      align: "right",
    },
    // {
    //   field: "OOS",
    //   headerName: "OOS",
    //   width: 130,
    //   headerAlign: "right",
    //   align: "right",
    // },
  ];
  return (
    <div className="custom-scrollbar">
      <Header showIcon={false} screenSize={size} />

      <Box>
        {" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            overflowX: "auto",
            margin: "5px",
            backgroundColor: theme.palette.thirdColor.main,
          }}
        >
          {instaLocation?.map((val, index) => {
            return (
              <div
                key={index}
                className={`${classes.card} ${index === selectedCard ? classes.selectedCard : ""
                  }`}
                onClick={() => handleCardClick(index)}
              >
                <>
                  <div
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      marginBottom: "5px",
                    }}
                  >
                    {val?.Name1}
                  </div>
                  <div>
                    <b> Last Sync :</b>
                    {moment
                      .utc(val?.Insta_Update)
                      .format("YYYY-MM-DD hh:mm:ss A")}
                  </div>{" "}
                  <div>
                    <b> Last Update :</b>
                    {moment.utc(val?.Insta_CAL).format("YYYY-MM-DD hh:mm:ss A")}
                  </div>
                </>
              </div>
            );
          })}
        </div>
      </Box>
      <Box sx={{ height: 360, margin: "0 15px" }}>
        <DataGrid
          rows={rows ?? []}
          columns={columns}
          sx={{
            border: 2,
            borderColor: "primary.light",
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.primary.main,
              fontSize: 16,
            },
          }}
          getRowId={(row) => row?.ItemCode + row?.UnitName + row?.ItemName}
          rowHeight={20}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </Box>
      <AppBar
        position="fixed"
        color="secondary"
        sx={{ top: "auto", bottom: 0 }}
      >
        <Toolbar>
          <Container sx={{ display: "flex", justifyContent: "space-evenly" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleCalClick()}
            >
              Update RSP
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSyncClick()}
            >
              Synchronize
            </Button>
          </Container>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default InstaIntegration;
