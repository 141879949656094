import React from "react";

const RechartsCustomTooltip = ({ active, payload }) => {
  if (active) {
    // The payload contains the data for the tooltip, you can access it as an array  ${entry.dataKey}:
    const tooltipItems =
      payload &&
      payload?.map((entry, index) => (
        <p key={index} className={index === 0 ? "chart-tooltip-title" : ""} >{`${entry?.value.toLocaleString()}`}</p>
      ));

    return <div className="custom-tooltip">{tooltipItems}</div>;
  }

  return null;
};

export default RechartsCustomTooltip;
