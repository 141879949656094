import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Typography, Button, Paper } from "@mui/material";
import { Link } from "react-router-dom";

const Success = () => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    justifyContent: "center",
    alignItems: "center",
  };

  const paperStyle = {
    padding: "20px",
    border: "2px solid #4CAF50", // Green border
    boxShadow: "0px 8px 16px rgba(76, 175, 80, 0.1)", // Green shadow effect
  };

  const headerStyle = {
    textAlign: "center",
    marginBottom: "20px",
  };

  const titleStyle = {
    // fontSize: "36px",
    fontWeight: "bold",
    margin: "0",
  };

  const mainContentStyle = {
    textAlign: "center",
    marginTop: "20px",
  };

  const checkmarkStyle = {
    fontSize: "48px",
    color: "#4CAF50", // Green color
  };

  const bodyStyle = {
    fontSize: "16px",
    lineHeight: "1.5",
    color: "#333",
    marginTop: "10px",
    marginBottom: "20px",
  };

  const buttonStyle = {
    backgroundColor: "#4CAF50", // Green color
    color: "#fff", // White text
  };

  const footerStyle = {
    textAlign: "center",
    marginTop: "20px",
  };

  const fineprintStyle = {
    fontSize: "12px",
    color: "#888",
  };

  return (
    <div style={containerStyle}>
      <Paper elevation={3} style={paperStyle}>
        <header style={headerStyle}>
          <Typography variant="h5" style={titleStyle}>
            THANK YOU!
          </Typography>
        </header>

        <div style={mainContentStyle}>
          <CheckCircleIcon style={checkmarkStyle} id="checkmark" />
          <Typography variant="body1" style={bodyStyle}>
            Profile has been successfully submitted
          </Typography>

          {/* "Back to Home" button */}
        </div>

        <footer style={footerStyle}>
          <Button
            component={Link}
            to="/customer-page" // Specify the route to navigate to
            variant="contained"
            style={buttonStyle}
            size={"small"}
          >
            Home
          </Button>
        </footer>
      </Paper>
    </div>
  );
};

export default Success;
