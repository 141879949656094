import "./sidebar.css";
import React, { useLayoutEffect, useEffect, useState } from "react";
//material ui
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
// import Typography from "@mui/material/Typography";

//images
import logo from "../../../assets/images/logo-1.png";

// react router dom
import { Outlet } from "react-router-dom";
// components
import Header from "../header/header";
import Filters from "../../../pages/Filters/Filters";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setResponsiveSize } from "./slice";

const drawerWidth = "20%"; // Set to 20% of the viewport width

export default function Sidebar() {
  const dispatch = useDispatch();

  const size = useSelector((state) => state?.sidebarSlice?.screenSize);

  const [responsive, setResponsive] = useState(false);
  const [headerIconShow, setHeaderIconShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (open) => {
    setIsOpen(open);
  };

  useLayoutEffect(() => {
    function updateSize() {
      dispatch(setResponsiveSize(window.innerWidth));
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [size]);

  // component update
  useEffect(() => {
    if (size <= 900) {
      setResponsive(true);
      setHeaderIconShow(true);
    } else {
      setResponsive(false);
      setHeaderIconShow(false);
    }
  }, [size, responsive]);

  return (
    <>
      {responsive ? (
        // Mobile Sidebar
        <>
          <Drawer
            id="mobile-sidebar"
            anchor="left"
            open={isOpen}
            onClose={() => toggleDrawer(false)}
          >
            <Box
              sx={{
                width: 250,
              }}
            >
              <div style={{ padding: "10px" }}>
                <img src={logo} alt="gravity_logo" className="image" />
              </div>
              <Divider />
              <Filters onClose={() => toggleDrawer(false)} />
            </Box>
          </Drawer>

          <Box
            component="main"
            className="mobile-layout"
          >
            <Header
              hanldeClick={() => toggleDrawer(!isOpen)}
              showIcon={headerIconShow}
              screenSize={size}
            />
            <Outlet />
          </Box>
        </>
      ) : (
        // WEb sidebar
        <div
          style={{
            borderRadius: "10px",
            margin: "auto",
            backgroundColor: "white",
            marginTop: "6px",
          }}
        >
          <Header showIcon={headerIconShow} screenSize={size} />
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "18.5%" }}>
              <Filters onClose={() => toggleDrawer(false)} />
            </div>
            <Box
              sx={{
                width: "81.5%",
                height: "86vh",
                overflow: "auto",
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </div>
      )}
    </>
  );
}
