import React, { useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";

import RoundedCheckboxWithLabel from "../../../components/RoundedCheckbox";

import { useDispatch, useSelector } from "react-redux";
import {
  selectLocation,
  selectSelectedLocation,
  setSelectedLocation,
} from "../Slice";
import { Typography } from "@mui/material";

function Checklist() {
  const dispatch = useDispatch();
  const location = useSelector((state) => state?.filterSlice?.location);

  useEffect(() => {
    if (location) {
      dispatch(setSelectedLocation([...location]));
    }
  }, [location]);

  const selectedLocations = useSelector(
    (state) => state?.filterSlice?.selectedLocation
  );
  const handleLocationToggle = (location) => {
    const currentIndex = selectedLocations.indexOf(location);
    const newSelectedLocations = [...selectedLocations];

    if (currentIndex === -1) {
      newSelectedLocations.push(location);
    } else {
      newSelectedLocations.splice(currentIndex, 1);
    }
    dispatch(setSelectedLocation(newSelectedLocations));
  };

  const handleSelectAll = () => {
    if (selectedLocations.length === location.length) {
      dispatch(setSelectedLocation([]));
    } else {
      dispatch(setSelectedLocation([...location]));
    }
  };

  return (
    <div style={{ padding: "0px 8px", height: "308px", overflowY: "scroll" }}>
      <Typography variant="subtitle1" sx={{ fontSize: "14px" }}>
        Location
      </Typography>{" "}
      <FormGroup>
        <RoundedCheckboxWithLabel
          label="Select All"
          checked={selectedLocations?.length === location?.length}
          onChange={handleSelectAll}
        />

        {location &&
          location.map((location) => (
            <RoundedCheckboxWithLabel
              key={location.UId}
              label={location.Name1}
              checked={selectedLocations.includes(location)}
              onChange={() => handleLocationToggle(location)}
            />
          ))}
      </FormGroup>
    </div>
  );
}

export default Checklist;
