import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
} from "@material-ui/core";
import { Box, IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import React, { useRef } from "react";
import { useSelector } from "react-redux";
import theme from "../../theme/theme";
import { fAlertToast } from "../../Utility/Utility";
const LastFiveYearProfitLoss = ({ data }) => {
  const tableRef = useRef(null);

  const useStyles = makeStyles((themeConfig) => ({
    cell: {
      padding: "2px 6px !important",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "2px",
      width: "1px",
      margin: "0px",
      fontSize: "10.5px",
    },
    tableStyle: {
      width: "100%",
      overflowY: "auto",
      height: "78vh",
      padding: "0 5px",
      [themeConfig.breakpoints.down("sm")]: {
        width: "100%",
        height: "78vh",
      },
    },
    hideHeading: {
      display: "flex",

      [themeConfig.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    responsiveWidth: {
      width: "40%",
      [themeConfig.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    headingMargin: {
      marginTop: "0px",
      [themeConfig.breakpoints.down("sm")]: {
        marginTop: "50px",
      },
    },
  }));
  const classes = useStyles();

  const calculateColumnSum = (columnName) => {
    return data?.reduce(
      (sum, item) => sum + (parseFloat(item[columnName]) || 0),
      0
    );
  };

  const activeChartSectionTitle = useSelector(
    (state) => state.dashboardSlice.activeChartSectionTitle
  );

  const downloadCSV = (data, filename) => {
    if (data && data.length) {
      const arrayToCSV = (array) => {
        const header = Object.keys(array[0]).join(",") + "\n";
        const rows = array.map((obj) => Object.values(obj).join(",") + "\n");
        return header + rows.join("");
      };

      const csv = arrayToCSV(data);
      const blob = new Blob([csv], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename || "data.csv";
      link.click();
    } else {
      fAlertToast("WARNING", "NO Data Found !");
    }
  };
  return (
    <>
      {/*  */}
      <div className={classes.responsiveWidth}>
        <div className={classes.headingMargin}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: " 0px 10px",
            }}
          >
            <h5
              style={{ textAlign: "center", margin: "5px" }}
              className={classes.hideHeading}
            >
              {activeChartSectionTitle}
            </h5>
            <IconButton
              sx={{ p: 0 }}
              onClick={() => downloadCSV(data, "Hourly")}
            >
              <FileDownloadIcon sx={{ width: "0.8em", height: "0.8em" }} />
            </IconButton>
          </Box>
        </div>

        <div className={classes.tableStyle}>
          <Table
            sx={{
              width: "100%",
              border: theme.palette.primary.main,
              borderRadius: "10px",
              position: "relative",
            }}
            ref={tableRef}
            id="myTable"
          >
            <TableHead
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: theme.palette.secondary.main,
                zIndex: 1,
                color: "white !important",
              }}
            >
              <TableRow style={{ height: "35px" }}>
                {" "}
                <TableCell
                  align="right"
                  style={{
                    width: "15%",
                    color: theme.palette.primary.main,
                    fontSize: "15px",
                  }}
                  className={classes.cell}
                  padding="none"
                >
                  Period{" "}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    width: "20%",
                    color: theme.palette.primary.main,
                    fontSize: "15px",
                  }}
                  className={classes.cell}
                  padding="none"
                >
                  Income
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    width: "20%",
                    color: theme.palette.primary.main,
                    fontSize: "15px",
                  }}
                  className={classes.cell}
                  padding="none"
                >
                  Expense
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    width: "25%",
                    color: theme.palette.primary.main,
                    fontSize: "15px",
                  }}
                  className={classes.cell}
                  padding="none"
                >
                  Profit{" "}
                </TableCell>{" "}
                <TableCell
                  align="right"
                  style={{
                    width: "25%",
                    color: theme.palette.primary.main,
                    fontSize: "15px",
                  }}
                  className={classes.cell}
                  padding="none"
                >
                  %
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow key={index}>
                  {" "}
                  <TableCell
                    align="right"
                    className={classes.cell}
                    padding="none"
                  >
                    {item?.Period}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.cell}
                    padding="none"
                  >
                    {item?.Income?.toLocaleString()}
                  </TableCell>{" "}
                  <TableCell
                    align="right"
                    className={classes.cell}
                    padding="none"
                  >
                    {item?.Expense?.toLocaleString()}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.cell}
                    padding="none"
                  >
                    {item?.Profit?.toLocaleString()}
                  </TableCell>{" "}
                  <TableCell
                    align="right"
                    className={classes.cell}
                    padding="none"
                  >
                    {item?.NetProfitPerc?.toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow
                style={{
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: theme.palette.secondary.main,
                  zIndex: 2,
                  color: "white !important",
                  height: "35px",
                }}
              >
                {" "}
                <TableCell
                  style={{
                    width: "15%",
                    color: theme.palette.primary.main,
                    fontSize: "15px",
                  }}
                  className={classes.cell}
                  padding="none"
                  align="right"
                >
                  Total{" "}
                </TableCell>
                <TableCell
                  style={{
                    width: "20%",
                    color: theme.palette.primary.main,
                    fontSize: "15px",
                  }}
                  className={classes.cell}
                  padding="none"
                  align="right"
                >
                  {calculateColumnSum("Income").toLocaleString()}
                </TableCell>
                <TableCell
                  style={{
                    width: "20%",
                    color: theme.palette.primary.main,
                    fontSize: "15px",
                  }}
                  className={classes.cell}
                  padding="none"
                  align="right"
                >
                  {calculateColumnSum("Expense").toLocaleString()}
                </TableCell>{" "}
                <TableCell
                  style={{
                    width: "20%",
                    color: theme.palette.primary.main,
                    fontSize: "15px",
                  }}
                  className={classes.cell}
                  padding="none"
                  align="right"
                >
                  {calculateColumnSum("Profit").toLocaleString()}
                </TableCell>
                <TableCell
                  style={{
                    width: "25%",
                    color: theme.palette.primary.main,
                    fontSize: "15px",
                  }}
                  className={classes.cell}
                  padding="none"
                  align="right"
                >
                  {(
                    (calculateColumnSum("Profit") /
                      calculateColumnSum("Income")) *
                    100
                  ).toFixed(2)}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </div>
    </>
  );
};

export default LastFiveYearProfitLoss;
