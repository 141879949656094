import React, { useEffect, useRef, useState } from "react";
//material ui
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Box, IconButton, Stack } from "@mui/material";
// icons
import FileDownloadIcon from "@mui/icons-material/FileDownload";
// card
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

// recharts
import {
  LineChart,
  Line,
  Legend,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
  CartesianGrid,
  Label,
} from "recharts";
//redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveChartSection,
  setActiveChartSectionTitle,
  setBranchWiseSales,
  setCustomerCountAnalyticsData,
  setDailyStock,
  setDateWisePurchaseVSales,
  setDateWiseSales,
  setDepartmentWiseSales,
  setHourlySales,
  setInventoryAnalyticsData,
  setPosCustomerCount,
  setSalesLoss,
  setSalesLossAnalyticalData,
  setSalesVSCogsWiseAnalyticsData,
  setSalesVSPurchaseWiseAnalyticsData,
  setStock,
  setStockAnalyticsData,
  setSubmit,
  setActiveChartColumns,
  setActiveChartSectionFooter,
  seHourAnalytics,
  resetState,
  setBranchAnalytics,
  setDepartmentAnalytics,
  setCurrentMonthWise,
  setLastFiveYear,
  SetCurrentYearBranch,
  setCurrentYearProfitLossAnalytics,
  setCurrentYearBranchProfitLossAnalytics,
  setLastFiveYearProfitLossAnalytics,
  setBranchWiseStock,
  setDepartmentWiseStock
} from "./slice";
import {
  getBranchWiseData,
  getBranchWiseSales,
  getCurrentMonthWise,
  getCurrentYearBranchWise,
  getCustomerCountAnalyticsData,
  getDailyStock,
  getDateWiseAnalyticsData,
  getDateWisePurchaseVSales,
  getDateWiseSales,
  getDepartmentData,
  getDepartmentWiseSales,
  getHourData,
  getHourlySales,
  getInventoryData,
  getLastFiveYear,
  getLossData,
  getPosCustomerCount,
  getSalesVsPurchase,
  getStockChartAnalyticsData,
  getWebSalesLoss,
  getWebStock,
  getYearlyProfitLoss,
  getBranchwiseStock,
  getDepartmentWiseStock
} from "./action";

//chart exports imports
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import theme from "../../theme/theme";
// component
import Modal from "../../components/Modal/Modal";
import RechartsCustomTooltip from "../../components/RechartsCustomTooltip";
import moment from "moment";
import { setActive, setFromDate, setToDate } from "../Filters/Slice";
import {
  bindBranch,
  bindDep,
  bindHourlySalesColumns,
  bindInvoiceColumns,
  bindPurchaseVSales,
  bindSalesVCogs,
  bindStockColumns,
} from "./utils";
import DataTable from "../../components/table/HtmlTable";
import BranchWise from "../../components/table/BranchWiseTable";
import PurchaseVSales from "../../components/table/PurchaseVSalesTable";
import SalesVCogsTable from "../../components/table/SalesVCogsTable";
import HourlyTable from "../../components/table/HourlyTable";
import InvoiceTable from "../../components/table/InvoiceTable";
import StockLoss from "../../components/table/StockLoss";
import StockTable from "../../components/table/Stock";
import CurrentYearProfitLoss from "../../components/table/CurrentYearProfitLoss";
import LastFiveYearProfitLoss from "../../components/table/LastFiveYearProfitLoss";
import CurrentYearBranchWiseProfitLoss from "../../components/table/CurrentYearBranchwiseProfitLoss";
import BranchWiseStockTable from "../../components/table/BranchWiseStockTable";
import DepartmentWiseStockTable from "../../components/table/DepartmentWiseStockTable";

//styles
const useStyles = makeStyles((themeConfig) => ({
  container: {
    height: "86vh",
    padding: "12px 0",
    rowGap: "16px",
  },
  chartsSection: {
    borderRadius: "5px",
    paddingTop: 0,
    padding: "24px ",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    overflow: "auto",
    height: "82vh",
    backgroundColor: theme.palette.thirdColor.main,
    [themeConfig.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },
  userProfile: {
    borderRadius: "5px",
    paddingTop: 0,
    paddingBottom: 0,
    padding: "8px ",
    // overflow: "scroll",
    // height: "82vh",
  },
  card_header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  chart_header: {
    fontSize: "12px",
    color: "black",
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    width: "10%",
    height: "12px",
    margin: "auto",
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    width: "10%",
    height: "12px",
    margin: "auto",
  },
  leftCalculationCaption: {
    width: "60%",
    // marginLeft: "5px",
    margin: "auto",
    fontSize: "smaller",
  },
  rightCalculationCaption: {
    width: "40%",
    margin: "auto",
    fontSize: "smaller",
  },
  calculationContainer: {
    margin: "auto",
    height: "30px",
    [themeConfig.breakpoints.down("xs")]: {
      height: "60px",
    },
  },
  inventoryChart: {
    height: "260px",
    [themeConfig.breakpoints.down("xs")]: {
      height: "300px",
    },
  },
}));

const TableComponent = ({ data, activeChartSection }) => {
  let componentToRender;
  switch (activeChartSection) {
    case "currentYearProfitLoss":
      componentToRender = <CurrentYearProfitLoss data={data} />;
      break;
    case "lastFiveYearProfitLoss":
      componentToRender = <LastFiveYearProfitLoss data={data} />;
      break;
    case "currentYearBranchWiseProfitLoss":
      componentToRender = <CurrentYearBranchWiseProfitLoss data={data} />;
      break;
    case "branch":
      componentToRender = <BranchWise data={data} />;
      break;
    case "dep":
      componentToRender = <DataTable data={data} />;
      break;
    case "purchaseVSales":
      componentToRender = <PurchaseVSales data={data} />;
      break;
    case "SalesVCogs":
      componentToRender = <SalesVCogsTable data={data} />;
      break;
    case "hourly":
      componentToRender = <HourlyTable data={data} />;
      break;
    case "invoice":
      componentToRender = <InvoiceTable data={data} />;
      break;
    case "stock":
      componentToRender = <StockTable data={data} />;
      break;
    case "salessloss":
      componentToRender = <StockLoss data={data} />;
      break;
    case "inventory":
      componentToRender = <></>;
      break;
    case "BranchWiseStock":
      componentToRender = <BranchWiseStockTable data={data} />;
      break;
    case "DepartmentWiseStock":
      componentToRender = <DepartmentWiseStockTable data={data} />;
      break;
    default:
      componentToRender = <></>;
  }

  return <>{componentToRender}</>;
};

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // refs for chart section downloads
  const chartRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const downloadBtnRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  // States
  const [open, setOpen] = useState(false);

  const fromDate = useSelector((state) => state?.filterSlice?.from);
  const toDate = useSelector((state) => state?.filterSlice?.to);
  const clientId = useSelector((state) => state?.loginSlice?.clientId);
  const features = useSelector((state) => state?.loginSlice?.feature);

  const data = useSelector(
    (state) => state?.dashboardSlice?.activeChartSection
  );

  const salesVSCogsAnalyticsData = useSelector(
    (state) => state?.dashboardSlice?.salesVSCogsWiseAnalyticsData
  );
  const salesVSPurchaseAnalyticsData = useSelector(
    (state) => state?.dashboardSlice?.salesVSPurchaseWiseAnalyticsData
  );
  const stockAnalyticsData = useSelector(
    (state) => state?.dashboardSlice?.stockAnalyticsData
  );
  const salesLossAnalyticalData = useSelector(
    (state) => state?.dashboardSlice?.salesLossAnalyticalData
  );
  const customerCountAnalyticsData = useSelector(
    (state) => state?.dashboardSlice?.customerCountAnalyticsData
  );
  const branchAnalyticsData = useSelector(
    (state) => state?.dashboardSlice?.branchWiseAnalyticsData
  );
  const departmentAnalyticsData = useSelector(
    (state) => state?.dashboardSlice?.departmentWiseAnalyticsData
  );
  const inventoryChartData = useSelector(
    (state) => state?.dashboardSlice?.inventoryAnalyticsData
  );

  const deptWiseSales = useSelector(
    (state) => state.dashboardSlice.departmentWiseSales
  );
  const branchWiseSalesData = useSelector(
    (state) => state.dashboardSlice.branchWiseSales
  );
  const dateWisePurchaseVSales = useSelector(
    (state) => state.dashboardSlice.dateWisePurchaseVSales
  );
  const hourlySales = useSelector((state) => state.dashboardSlice.hourlySales);
  const salesLoss = useSelector((state) => state.dashboardSlice.salesLoss);
  const invoice = useSelector((state) => state.dashboardSlice.posCustomerCount);
  const webStock = useSelector((state) => state.dashboardSlice.stock);
  const currentMonthWise = useSelector(
    (state) => state.dashboardSlice.currentMonthWise
  );
  const lastFiveYear = useSelector(
    (state) => state.dashboardSlice.lastFiveYear
  );
  const currentYearBranch = useSelector(
    (state) => state.dashboardSlice.currentYearBranch
  );
  const dailyStock = useSelector((state) => state.dashboardSlice.dailyStock);
  const dateWiseSales = useSelector(
    (state) => state.dashboardSlice.dateWiseSales
  );
  const hourAnalyticsData = useSelector(
    (state) => state.dashboardSlice.HourAnalyticsData
  );
  const screenSize = useSelector((state) => state?.sidebarSlice?.screenSize);

  const selectedLocations = useSelector(
    (state) => state.filterSlice.selectedLocation
  );
  const isSubmitted = useSelector((state) => state.dashboardSlice.isSubmitted);
  const chartTitle = useSelector(
    (state) => state.dashboardSlice.activeChartSectionTitle
  );
  const activeChartSectionFooter = useSelector(
    (state) => state.dashboardSlice.activeChartSectionFooter
  );

  const currentYearProfitLossAnalytics = useSelector(
    (state) => state.dashboardSlice.currentYearProfitLossAnalytics
  );
  const lastFiveYearProfitLossAnalytics = useSelector(
    (state) => state.dashboardSlice.lastFiveYearProfitLossAnalytics
  );
  const currentYearBranchProfitLossAnalytics = useSelector(
    (state) => state.dashboardSlice.currentYearBranchProfitLossAnalytics
  );

  // branch Stock

  const branchWiseStockData = useSelector(
    (state) => state.dashboardSlice.branchWiseStock
  );

  const deptWiseStockData = useSelector(
    (state) => state.dashboardSlice.departmentWiseStock
  );


  // Handles active chart data for the third sections
  const handleActiveChartSection = (type) => {
    if (type === "DepartmentWiseSales") {
      dispatch(setActiveChartSection(deptWiseSales ? [...deptWiseSales] : []));
      dispatch(setActiveChartSectionTitle("Department Wise Sales"));
      dispatch(setActiveChartColumns(bindDep(deptWiseSales)));
      dispatch(setActiveChartSectionFooter("dep"));
      if (screenSize < 900) {
        handleOpenModal();
        return;
      }
      return;
    } else if (type === "currentYearProfitLoss") {
      dispatch(
        setActiveChartSection(currentMonthWise ? [...currentMonthWise] : [])
      );
      dispatch(setActiveChartSectionTitle("Current Year Profit Loss"));
      dispatch(setActiveChartColumns(bindBranch(currentMonthWise)));
      dispatch(setActiveChartSectionFooter("currentYearProfitLoss"));

      if (screenSize < 900) {
        handleOpenModal();
        return;
      }
      return;
    } else if (type === "lastFiveYearProfitLoss") {
      dispatch(setActiveChartSection(lastFiveYear ? [...lastFiveYear] : []));
      dispatch(setActiveChartSectionTitle("Last Five Year Profit Loss"));
      dispatch(setActiveChartColumns([]));
      dispatch(setActiveChartColumns(bindBranch(lastFiveYear)));
      dispatch(setActiveChartSectionFooter("lastFiveYearProfitLoss"));

      if (screenSize < 900) {
        handleOpenModal();
        return;
      }
      return;
    } else if (type === "currentYearBranchWiseProfitLoss") {
      dispatch(
        setActiveChartSection(currentYearBranch ? [...currentYearBranch] : [])
      );
      dispatch(
        setActiveChartSectionTitle("Current Year Branch Wise Profit Loss")
      );
      dispatch(setActiveChartColumns([]));
      dispatch(setActiveChartColumns(bindBranch(currentYearBranch)));
      dispatch(setActiveChartSectionFooter("currentYearBranchWiseProfitLoss"));

      if (screenSize < 900) {
        handleOpenModal();
        return;
      }
      return;
    } else if (type === "BranchWiseSales") {
      dispatch(
        setActiveChartSection(
          branchWiseSalesData ? [...branchWiseSalesData] : []
        )
      );
      dispatch(setActiveChartSectionTitle("Branch Wise Sales"));
      dispatch(setActiveChartColumns([]));
      dispatch(setActiveChartColumns(bindBranch(branchWiseSalesData)));
      dispatch(setActiveChartSectionFooter("branch"));

      if (screenSize < 900) {
        handleOpenModal();
        return;
      }
      return;
    } else if (type === "purchaseVSales") {
      dispatch(
        setActiveChartSection(
          dateWisePurchaseVSales ? [...dateWisePurchaseVSales] : []
        )
      );
      dispatch(setActiveChartSectionTitle("Purchase Vs Sales"));
      dispatch(
        setActiveChartColumns(bindPurchaseVSales(dateWisePurchaseVSales))
      );
      dispatch(setActiveChartSectionFooter("purchaseVSales"));

      if (screenSize < 900) {
        handleOpenModal();
        return;
      }
      return;
    } else if (type === "SalesVCogs") {
      dispatch(setActiveChartSection([]));
      dispatch(setActiveChartSection(dateWiseSales ? [...dateWiseSales] : []));
      dispatch(setActiveChartSectionTitle("Date-Wise Sales"));
      dispatch(setActiveChartColumns([]));
      dispatch(setActiveChartColumns(bindSalesVCogs(dateWiseSales)));
      dispatch(setActiveChartSectionFooter("SalesVCogs"));

      if (screenSize < 900) {
        handleOpenModal();
        return;
      }
      return;
    } else if (type === "hourlySales") {
      dispatch(setActiveChartSection([]));
      dispatch(setActiveChartSection(hourlySales ? [...hourlySales] : []));
      dispatch(setActiveChartSectionTitle("Hourly Sales"));
      dispatch(setActiveChartColumns([]));
      dispatch(setActiveChartColumns(bindHourlySalesColumns(hourlySales)));
      dispatch(setActiveChartSectionFooter("hourly"));

      if (screenSize < 900) {
        handleOpenModal();
        return;
      }
      return;
    } else if (type === "hourlyCount") {
      dispatch(setActiveChartSection([]));
      dispatch(setActiveChartSection(hourlySales ? [...hourlySales] : []));
      dispatch(setActiveChartSectionTitle("Hourly Count"));
      dispatch(setActiveChartColumns([]));
      dispatch(setActiveChartColumns(bindHourlySalesColumns(hourlySales)));
      dispatch(setActiveChartSectionFooter("hourly"));

      if (screenSize < 900) {
        handleOpenModal();
        return;
      }
      return;
    } else if (type === "invoice") {
      dispatch(setActiveChartSection([]));
      dispatch(setActiveChartSection(invoice ? [...invoice] : []));
      dispatch(setActiveChartSectionTitle("Invoice"));
      dispatch(setActiveChartColumns([]));
      dispatch(setActiveChartColumns(bindInvoiceColumns(invoice)));
      dispatch(setActiveChartSectionFooter("invoice"));

      if (screenSize < 900) {
        handleOpenModal();
        return;
      }
      return;
    } else if (type === "stock") {
      dispatch(setActiveChartSection([]));
      dispatch(setActiveChartSection(dailyStock ? [...dailyStock] : []));
      dispatch(setActiveChartSectionTitle("Stock"));
      dispatch(setActiveChartColumns([]));
      dispatch(setActiveChartColumns(bindStockColumns(dailyStock)));
      dispatch(setActiveChartSectionFooter("stock"));

      if (screenSize < 900) {
        handleOpenModal();
        return;
      }
      return;
    } else if (type === "inventory") {
      dispatch(setActiveChartSection([]));
      dispatch(setActiveChartSection(salesLoss ? [...salesLoss] : []));
      dispatch(setActiveChartSectionTitle(""));
      dispatch(setActiveChartColumns([]));
      // dispatch(setActiveChartColumns("salesloss"));
      dispatch(setActiveChartSectionFooter("inventory"));

      // if (screenSize < 900) {
      //   handleOpenModal();
      //   return;
      // }
      return;
    } else if (type === "salesLoss") {
      dispatch(setActiveChartSection([]));
      dispatch(setActiveChartSection(salesLoss ? [...salesLoss] : []));
      dispatch(setActiveChartSectionTitle("Sales Loss"));
      dispatch(setActiveChartColumns([]));
      // dispatch(setActiveChartColumns("salesloss"));
      dispatch(setActiveChartSectionFooter("salessloss"));

      if (screenSize < 900) {
        handleOpenModal();
        return;
      }
      return;
    } else if (type === "BranchWiseStock") {
      dispatch(setActiveChartSection([]));
      dispatch(
        setActiveChartSection(
          branchWiseStockData ? [...branchWiseStockData] : []
        )
      );
      dispatch(setActiveChartSectionTitle("Branch Wise Stock"));
      dispatch(setActiveChartColumns([]));
      dispatch(setActiveChartSectionFooter("BranchWiseStock"));

      if (screenSize < 900) {
        handleOpenModal();
        return;
      }
      return;
    } else if (type === "DepartmentWiseStock") {
      dispatch(setActiveChartSection([]));
      dispatch(
        setActiveChartSection(
          deptWiseStockData ? [...deptWiseStockData] : []
        )
      );
      dispatch(setActiveChartSectionTitle("Department Wise Stock"));
      dispatch(setActiveChartColumns([]));
      dispatch(setActiveChartSectionFooter("DepartmentWiseStock"));

      if (screenSize < 900) {
        handleOpenModal();
        return;
      }
      return;
    }

  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  // Export chart data as a pdf
  const exportToPDF = async (name, index) => {
    if (downloadBtnRefs[index].current)
      downloadBtnRefs[index].current.style.visibility = "hidden";
    const canvas = await html2canvas(chartRefs[index].current);
    const imgData = canvas.toDataURL("image/png");
    const aspectRatio = canvas.width / canvas.height;
    const pdfWidth = 200;
    const pdfHeight = pdfWidth / aspectRatio;
    const pdf = new jsPDF();
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(name);
    if (downloadBtnRefs[index].current)
      downloadBtnRefs[index].current.style.visibility = "visible";
  };

  const onSubmit = () => {
    const br = selectedLocations
      .map((val) => {
        return val?.UId;
      })
      .toString();
    const formattedFromDate = moment(fromDate).format("MM/DD/YYYY");
    const formattedToDate = moment(toDate).format("MM/DD/YYYY");
    let Payload = {
      fromDate: formattedFromDate,
      toDate: formattedToDate,
      br,
      clientId,
    };
    getAllData(Payload);
  };

  const getAllData = async (obj) => {
    try {
      const [
        branch,
        dept,
        salesVPurchase,
        dateWiseSales,
        posCustomerCount,
        hourlySales,
        salesLoss,
        stock,
        dailyStock,
        currentMonthWise,
        lastFiveYear,
        currentYearBranch,
        branchStock,
        deptStock
      ] = await Promise.all([
        getBranchWiseSales({ ...obj, Typ: "branch" }),
        getDepartmentWiseSales({ ...obj, Typ: "Dept" }), // Assuming you meant to call a different function for branch
        getDateWisePurchaseVSales(obj),
        getDateWiseSales(obj),
        getPosCustomerCount(obj),
        getHourlySales(obj),
        getWebSalesLoss(obj),
        getWebStock(obj),
        getDailyStock(obj),
        getCurrentMonthWise(obj),
        getLastFiveYear(obj),
        getCurrentYearBranchWise(obj),
        getBranchwiseStock({ ...obj, Typ: "branch" }),
        getDepartmentWiseStock({ ...obj, Typ: "branch" })
      ]);
      dispatch(setDepartmentWiseSales(dept?.data?.ResultSet));
      dispatch(setBranchWiseSales(branch?.data?.ResultSet));
      dispatch(setActiveChartSectionTitle("Branch Wise Sales"));
      dispatch(setActiveChartSection([]));
      dispatch(setActiveChartSection(currentMonthWise?.data?.ResultSet));
      dispatch(setActiveChartColumns([]));
      dispatch(setActiveChartColumns(bindBranch(branchWiseSalesData)));

      dispatch(setDateWisePurchaseVSales(salesVPurchase?.data?.ResultSet));
      dispatch(setBranchWiseStock(branchStock?.data?.ResultSet));
      dispatch(setDepartmentWiseStock(deptStock?.data?.ResultSet));

      dispatch(setStock(stock?.data?.ResultSet));
      dispatch(setDailyStock(dailyStock?.data?.ResultSet));

      const formattedDateData = dateWiseSales?.data?.ResultSet.map((item) => ({
        ...item,
        PostDt: moment(item.PostDt).format("DD/MM/YYYY"),
      }));
      dispatch(setDateWiseSales(formattedDateData));
      dispatch(SetCurrentYearBranch(currentYearBranch?.data?.ResultSet));
      dispatch(setLastFiveYear(lastFiveYear?.data?.ResultSet));
      dispatch(setCurrentMonthWise(currentMonthWise?.data?.ResultSet));

      const formattedPosCustomerCount = posCustomerCount?.data?.ResultSet.map(
        (item) => ({
          ...item,
          Date: moment(item.Date).format("DD/MM/YYYY"),
        })
      );
      dispatch(setPosCustomerCount(formattedPosCustomerCount));

      dispatch(setHourlySales(hourlySales?.data?.ResultSet));
      dispatch(seHourAnalytics(getHourData(hourlySales?.data?.ResultSet)));

      const formattedSalesLoss = salesLoss?.data?.ResultSet.map((item) => ({
        ...item,
        PostDt: moment(item.PostDt).format("DD/MM/YYYY"),
      }));
      dispatch(setSalesLoss(formattedSalesLoss));
      dispatch(setSubmit(false));
      if (dailyStock?.data?.ResultSet && dailyStock?.data?.ResultSet.length) {
        dispatch(
          setStockAnalyticsData(
            getStockChartAnalyticsData(dailyStock?.data?.ResultSet)
          )
        );
      }
      if (
        (formattedDateData && formattedDateData.length,
          webStock && webStock.length)
      ) {
        dispatch(
          setInventoryAnalyticsData(
            getInventoryData(formattedDateData, webStock)
          )
        );
      }
      //calculations
      if (branch?.data?.ResultSet && branch?.data?.ResultSet.length) {
        dispatch(
          setBranchAnalytics(getBranchWiseData(branch?.data?.ResultSet))
        );
      }
      if (dept?.data?.ResultSet && dept?.data?.ResultSet.length) {
        dispatch(
          setDepartmentAnalytics(getDepartmentData(dept?.data?.ResultSet))
        );
      }
      if (formattedDateData && formattedDateData.length) {
        dispatch(
          setSalesVSCogsWiseAnalyticsData(
            getDateWiseAnalyticsData(formattedDateData)
          )
        );
      }
      if (dateWisePurchaseVSales && dateWisePurchaseVSales.length) {
        dispatch(
          setSalesVSPurchaseWiseAnalyticsData(
            getSalesVsPurchase(dateWisePurchaseVSales)
          )
        );
      }

      if (formattedSalesLoss && formattedSalesLoss.length) {
        dispatch(setSalesLossAnalyticalData(getLossData(formattedSalesLoss)));
      }

      if (formattedPosCustomerCount && formattedPosCustomerCount.length) {
        dispatch(
          setCustomerCountAnalyticsData(
            getCustomerCountAnalyticsData(formattedPosCustomerCount)
          )
        );
      }
      if (
        lastFiveYear?.data?.ResultSet &&
        lastFiveYear?.data?.ResultSet.length
      ) {
        dispatch(
          setLastFiveYearProfitLossAnalytics(
            getYearlyProfitLoss(lastFiveYear?.data?.ResultSet)
          )
        );
      }
      if (
        currentYearBranch?.data?.ResultSet &&
        currentYearBranch?.data?.ResultSet.length
      ) {
        dispatch(
          setCurrentYearBranchProfitLossAnalytics(
            getYearlyProfitLoss(currentYearBranch?.data?.ResultSet)
          )
        );
      }
      if (
        currentMonthWise?.data?.ResultSet?.data?.ResultSet &&
        currentMonthWise?.data?.ResultSet?.data?.ResultSet.length
      ) {
        dispatch(
          setCurrentYearProfitLossAnalytics({
            Income: 419005447,
            Expense: 418159803,
            Profit: 845645,
            percentage: 0.20182195865343966,
          })
        );
      }
      if (screenSize > 900) {
        handleActiveChartSection("currentYearProfitLoss", "false");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    dispatch(setFromDate(new Date()));
    dispatch(setToDate(new Date()));
    dispatch(setActive("today"));
  }, [dispatch]);

  useEffect(() => {
    if (isSubmitted) {
      dispatch(resetState());
      onSubmit();
    }
  }, [isSubmitted, dispatch]);

  return (
    <Box>
      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={12} md={7} className={classes.chartsSection}>
          <Grid container spacing={1} sx={{ margin: "15px" }}>
            {
              <Grid item xs={12}>
                <div className={classes.card_header}>
                  <p className="chart-caption"> Current Year Profit/Loss</p>
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={() => exportToPDF("branch_wise_sales", 1)}
                    ref={downloadBtnRefs[1]}
                  >
                    <FileDownloadIcon
                      sx={{ width: "0.8em", height: "0.8em" }}
                    />
                  </IconButton>
                </div>
                <Card>
                  <div
                    onClick={() =>
                      handleActiveChartSection("currentYearProfitLoss")
                    }
                    ref={chartRefs[1]}
                  >
                    <CardContent className="chart-container">
                      <Grid
                        container
                        spacing={0}
                        xs={6}
                        sm={7}
                        md={8}
                        lg={7}
                        className={classes.calculationContainer}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Income
                            </p>

                            <p className={classes.rightCalculationCaption}>
                              {currentYearProfitLossAnalytics?.Income}
                            </p>
                          </Stack>
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Expense
                            </p>

                            <p className={classes.rightCalculationCaption}>
                              {" "}
                              {currentYearProfitLossAnalytics?.Expense}
                            </p>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Profit
                            </p>
                            <p className={classes.rightCalculationCaption}>
                              {currentYearProfitLossAnalytics?.Profit}
                            </p>
                          </Stack>
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>%</p>
                            <p className={classes.rightCalculationCaption}>
                              {currentYearProfitLossAnalytics?.percentage?.toFixed(
                                2
                              )}
                            </p>
                          </Stack>
                        </Grid>
                      </Grid>
                      <ResponsiveContainer width={"100%"} height={"100%"}>
                        <BarChart
                          data={currentMonthWise}
                          margin={{
                            top: 5,
                            right: 10,
                            left: 10,
                            bottom: 5,
                          }}
                          animationBegin={1000}
                          animationDuration={1000}
                        >
                          <XAxis
                            dataKey="Period"
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />
                          <YAxis
                            tickFormatter={(value) => {
                              return `${value.toLocaleString()}`;
                            }}
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Bar
                            dataKey="Income"
                            fill={theme.palette.primary.main}
                          />
                          <Bar
                            dataKey="Expense"
                            fill={theme.palette.secondary.main}
                          />
                          <Bar dataKey="Profit" fill="#8884d8" />
                        </BarChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </div>
                </Card>
              </Grid>
            }
            {features?.Last5YearProfitLoss && (
              <Grid item xs={12}>
                <div className={classes.card_header}>
                  <p className="chart-caption"> Last 5 Year Profit/Loss</p>
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={() => exportToPDF("branch_wise_sales", 1)}
                    ref={downloadBtnRefs[1]}
                  >
                    <FileDownloadIcon
                      sx={{ width: "0.8em", height: "0.8em" }}
                    />
                  </IconButton>
                </div>
                <Card>
                  <div
                    onClick={() =>
                      handleActiveChartSection("lastFiveYearProfitLoss")
                    }
                    ref={chartRefs[1]}
                  >
                    <CardContent className="chart-container">
                      <Grid
                        container
                        spacing={0}
                        xs={6}
                        sm={7}
                        md={8}
                        lg={7}
                        className={classes.calculationContainer}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Income
                            </p>

                            <p className={classes.rightCalculationCaption}>
                              {lastFiveYearProfitLossAnalytics?.Income?.toLocaleString()}
                            </p>
                          </Stack>
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Expense
                            </p>

                            <p className={classes.rightCalculationCaption}>
                              {" "}
                              {lastFiveYearProfitLossAnalytics?.Income?.toLocaleString()}
                            </p>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Net Profit
                            </p>
                            <p className={classes.rightCalculationCaption}>
                              {lastFiveYearProfitLossAnalytics?.Profit?.toLocaleString()}
                            </p>
                          </Stack>
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Net Profit %
                            </p>
                            <p className={classes.rightCalculationCaption}>
                              {lastFiveYearProfitLossAnalytics?.percentage?.toFixed(
                                2
                              )}
                            </p>
                          </Stack>
                        </Grid>
                      </Grid>
                      <ResponsiveContainer width={"100%"} height={"100%"}>
                        <BarChart
                          data={lastFiveYear}
                          margin={{
                            top: 5,
                            right: 10,
                            left: 10,
                            bottom: 5,
                          }}
                          animationBegin={1000}
                          animationDuration={1000}
                        >
                          <XAxis
                            dataKey="Period"
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />
                          <YAxis
                            tickFormatter={(value) => {
                              return `${value?.toLocaleString()}`;
                            }}
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />

                          <Legend iconType="none" />
                          <CartesianGrid strokeDasharray="3 3" />

                          <Bar
                            dataKey="Income"
                            fill={theme.palette.primary.main}
                          />
                          <Bar
                            dataKey="Expense"
                            fill={theme.palette.secondary.main}
                          />
                          <Bar dataKey="Profit" fill="#8884d8" />
                        </BarChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </div>
                </Card>
              </Grid>
            )}
            {features?.CurrentYearBranchWiseProfitLoss && (
              <Grid item xs={12}>
                <div className={classes.card_header}>
                  <p className="chart-caption">
                    {" "}
                    Current Year Branch wise Profit/Loss
                  </p>
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={() => exportToPDF("branch_wise_sales", 1)}
                    ref={downloadBtnRefs[1]}
                  >
                    <FileDownloadIcon
                      sx={{ width: "0.8em", height: "0.8em" }}
                    />
                  </IconButton>
                </div>
                <Card>
                  <div
                    onClick={() =>
                      handleActiveChartSection(
                        "currentYearBranchWiseProfitLoss"
                      )
                    }
                    ref={chartRefs[1]}
                  >
                    <CardContent className="chart-container">
                      <Grid
                        container
                        spacing={0}
                        xs={6}
                        sm={7}
                        md={8}
                        lg={7}
                        className={classes.calculationContainer}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Income
                            </p>

                            <p className={classes.rightCalculationCaption}>
                              {currentYearBranchProfitLossAnalytics?.Income?.toLocaleString()}
                            </p>
                          </Stack>
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Expense
                            </p>

                            <p className={classes.rightCalculationCaption}>
                              {" "}
                              {currentYearBranchProfitLossAnalytics?.Expense?.toLocaleString()}
                            </p>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Net Profit
                            </p>
                            <p className={classes.rightCalculationCaption}>
                              {currentYearBranchProfitLossAnalytics?.Profit?.toLocaleString()}
                            </p>
                          </Stack>
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Net Profit %
                            </p>
                            <p className={classes.rightCalculationCaption}>
                              {currentYearBranchProfitLossAnalytics?.percentage?.toFixed(
                                2
                              )}
                            </p>
                          </Stack>
                        </Grid>
                      </Grid>
                      <ResponsiveContainer width={"100%"} height={"100%"}>
                        <BarChart
                          data={currentYearBranch}
                          margin={{
                            top: 5,
                            right: 10,
                            left: 10,
                            bottom: 5,
                          }}
                          animationBegin={1000}
                          animationDuration={1000}
                        >
                          <XAxis
                            dataKey="Branch"
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />
                          <YAxis
                            tickFormatter={(value) => {
                              return `${value?.toLocaleString()}`;
                            }}
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />

                          <Legend iconType="none" />
                          <CartesianGrid strokeDasharray="3 3" />

                          <Bar
                            dataKey="Income"
                            fill={theme.palette.primary.main}
                          />
                          <Bar
                            dataKey="Profit"
                            fill={theme.palette.secondary.main}
                          />
                          <Bar dataKey="Expense" fill="#8884d8" />
                        </BarChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </div>
                </Card>
              </Grid>
            )}
            {features?.BranchWiseSales && (
              <Grid item xs={12}>
                <div className={classes.card_header}>
                  <p className="chart-caption">Branch Wise Sales</p>
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={() => exportToPDF("branch_wise_sales", 1)}
                    ref={downloadBtnRefs[1]}
                  >
                    <FileDownloadIcon
                      sx={{ width: "0.8em", height: "0.8em" }}
                    />
                  </IconButton>
                </div>
                <Card>
                  <div
                    onClick={() => handleActiveChartSection("BranchWiseSales")}
                    ref={chartRefs[1]}
                  >
                    <CardContent className="chart-container">
                      <Grid
                        container
                        spacing={0}
                        xs={6}
                        sm={7}
                        md={8}
                        lg={7}
                        className={classes.calculationContainer}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Sales
                            </p>

                            <p className={classes.rightCalculationCaption}>
                              {Number(
                                branchAnalyticsData?.sales
                              )?.toLocaleString()}
                            </p>
                          </Stack>
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Cogs
                            </p>

                            <p className={classes.rightCalculationCaption}>
                              {" "}
                              {Number(
                                branchAnalyticsData?.cogs
                              )?.toLocaleString()}
                            </p>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>GP</p>
                            <p className={classes.rightCalculationCaption}>
                              {Number(
                                branchAnalyticsData?.gp
                              )?.toLocaleString()}
                            </p>
                          </Stack>
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              GP %
                            </p>
                            <p className={classes.rightCalculationCaption}>
                              {Number(
                                branchAnalyticsData?.gpPercentage
                              )?.toLocaleString() + " % "}
                            </p>
                          </Stack>
                        </Grid>
                      </Grid>
                      <ResponsiveContainer width={"100%"} height={"100%"}>
                        <BarChart
                          data={branchWiseSalesData}
                          margin={{
                            top: 5,
                            right: 10,
                            left: 10,
                            bottom: 5,
                          }}
                          animationBegin={1000}
                          animationDuration={1000}
                        >
                          <XAxis
                            dataKey="Branch"
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />
                          <YAxis
                            tickFormatter={(value) => {
                              return `${value?.toLocaleString()}`;
                            }}
                            dataKey="BrSales"
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />

                          <CartesianGrid strokeDasharray="3 3" />

                          {branchWiseSalesData?.map((entry, index) => (
                            <Label
                              key={`label-${index}`}
                              value={entry.BrSales}
                              position="top"
                              fill="#888"
                            />
                          ))}
                          <Bar
                            dataKey="Branch"
                            barSize={15}
                            fill={theme.palette.primary.main}
                          />
                          <Bar
                            dataKey="BrSales"
                            barSize={15}
                            fill={theme.palette.primary.main}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </div>
                </Card>
              </Grid>
            )}

            {/* Department wise chart */}
            {features?.DepartmentWiseSales && (
              <Grid item xs={12}>
                <div className={classes.card_header}>
                  <p className="chart-caption">Department Wise Sales</p>

                  <IconButton
                    sx={{ p: 0 }}
                    onClick={() => exportToPDF("department_wise_sales", 0)}
                    ref={downloadBtnRefs[0]}
                  >
                    <FileDownloadIcon
                      sx={{ width: "0.8em", height: "0.8em" }}
                    />
                  </IconButton>
                </div>
                <Card>
                  <div
                    onClick={() =>
                      handleActiveChartSection("DepartmentWiseSales")
                    }
                    ref={chartRefs[0]}
                  >
                    <CardContent className="chart-container">
                      <Grid
                        container
                        spacing={0}
                        xs={6}
                        sm={7}
                        md={8}
                        lg={7}
                        className={classes.calculationContainer}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Sales
                            </p>

                            <p className={classes.rightCalculationCaption}>
                              {Number(
                                departmentAnalyticsData?.sales
                              )?.toLocaleString()}
                            </p>
                          </Stack>
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Cogs
                            </p>

                            <p className={classes.rightCalculationCaption}>
                              {" "}
                              {Number(
                                departmentAnalyticsData?.cogs
                              )?.toLocaleString()}
                            </p>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>GP</p>
                            <p className={classes.rightCalculationCaption}>
                              {Number(
                                departmentAnalyticsData?.gp
                              )?.toLocaleString()}
                            </p>
                          </Stack>
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              GP %
                            </p>
                            <p className={classes.rightCalculationCaption}>
                              {Number(
                                departmentAnalyticsData?.gpPercentage
                              )?.toLocaleString() + " % "}
                            </p>
                          </Stack>
                        </Grid>
                      </Grid>
                      <ResponsiveContainer width={"100%"} height={"100%"}>
                        <LineChart
                          data={deptWiseSales}
                          margin={{
                            top: 5,
                            right: 10,
                            left: 10,
                            bottom: 5,
                          }}
                          animationBegin={5000}
                          animationDuration={10000}
                        >
                          <XAxis
                            dataKey="Department"
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />

                          <YAxis
                            tickFormatter={(value) => {
                              return `${value?.toLocaleString()}`;
                            }}
                            dataKey="Sales"
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />
                          <Tooltip content={<RechartsCustomTooltip />} />
                          <Legend iconType="none" />
                          <CartesianGrid strokeDasharray="3 3" />

                          <Line
                            type="monotone"
                            dataKey="Department"
                            stroke={theme.palette.primary.main}
                          />
                          <Line
                            type="monotone"
                            dataKey="Sales"
                            stroke={theme.palette.primary.main}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </div>
                </Card>
              </Grid>
            )}
            {/* sales vs cogs  chart */}
            {features?.PurchaseVsSales && (
              <Grid item xs={12}>
                {" "}
                <div className={classes.card_header}>
                  <p className="chart-caption">Date-wise Sales</p>
                  <IconButton
                    sx={{ p: 0 }}
                    ref={downloadBtnRefs[3]}
                    onClick={() => exportToPDF("sales_vs_cogs", 3)}
                  >
                    <FileDownloadIcon
                      sx={{ width: "0.8em", height: "0.8em" }}
                    />
                  </IconButton>
                </div>
                <Card>
                  <div
                    onClick={() => handleActiveChartSection("SalesVCogs")}
                    ref={chartRefs[3]}
                  >
                    <CardContent className="chart-container">
                      <Grid
                        container
                        spacing={0}
                        xs={6}
                        sm={7}
                        md={8}
                        lg={7}
                        className={classes.calculationContainer}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Sales
                            </p>

                            <p className={classes.rightCalculationCaption}>
                              {Number(
                                departmentAnalyticsData?.sales
                              )?.toLocaleString()}
                            </p>
                          </Stack>
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Cogs
                            </p>

                            <p className={classes.rightCalculationCaption}>
                              {" "}
                              {Number(
                                salesVSCogsAnalyticsData?.cogs
                              )?.toLocaleString()}
                            </p>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>GP</p>
                            <p className={classes.rightCalculationCaption}>
                              {Number(
                                salesVSCogsAnalyticsData?.gp
                              )?.toLocaleString()}
                            </p>
                          </Stack>
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              GP %
                            </p>
                            <p className={classes.rightCalculationCaption}>
                              {Number(
                                salesVSCogsAnalyticsData?.gpPercentage
                              )?.toLocaleString() + " % "}
                            </p>
                          </Stack>
                        </Grid>
                      </Grid>

                      <ResponsiveContainer width={"100%"} height={"100%"}>
                        <AreaChart
                          data={dateWiseSales}
                          margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                          }}
                          animationBegin={1000}
                          animationDuration={1000}
                        >
                          <XAxis
                            dataKey="PostDt"
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />
                          <YAxis
                            tickFormatter={(value) => {
                              return `${value?.toLocaleString()}`;
                            }}
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />

                          <Tooltip content={<RechartsCustomTooltip />} />
                          <Legend iconType="none" />
                          <CartesianGrid strokeDasharray="3 3" />

                          <Area
                            type="monotone"
                            dataKey="DtLandVal"
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.primary.main}
                          />
                          <Area
                            type="monotone"
                            dataKey="DtAvgVal"
                            stroke={theme.palette.secondary.main}
                            fill={theme.palette.secondary.main}
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </div>
                </Card>
              </Grid>
            )}
            {/* sales vs purchase  chart */}
            {features?.PurchaseVsSales && (
              <Grid item xs={12}>
                <div className={classes.card_header}>
                  <p className="chart-caption">Purchase Vs Sales</p>
                  <IconButton
                    sx={{ p: 0 }}
                    ref={downloadBtnRefs[2]}
                    onClick={() => exportToPDF("purchase_vs_sales", 2)}
                  >
                    <FileDownloadIcon
                      sx={{ width: "0.8em", height: "0.8em" }}
                    />
                  </IconButton>
                </div>
                <Card>
                  <div
                    onClick={() => handleActiveChartSection("purchaseVSales")}
                    ref={chartRefs[2]}
                  >
                    <CardContent className="chart-container">
                      <Grid
                        container
                        spacing={0}
                        xs={6}
                        sm={8}
                        lg={7}
                        className={classes.calculationContainer}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Sales
                            </p>

                            <p className={classes.rightCalculationCaption}>
                              {Number(
                                salesVSPurchaseAnalyticsData?.sales
                              )?.toLocaleString()}
                            </p>
                          </Stack>
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Purchase
                            </p>

                            <p className={classes.rightCalculationCaption}>
                              {Number(
                                salesVSPurchaseAnalyticsData?.purchase
                              )?.toLocaleString()}
                            </p>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Difference
                            </p>
                            <p className={classes.rightCalculationCaption}>
                              {Number(
                                salesVSPurchaseAnalyticsData?.diff
                              )?.toLocaleString()}
                            </p>
                          </Stack>
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Ratio
                            </p>
                            <p className={classes.rightCalculationCaption}>
                              {Number(
                                salesVSPurchaseAnalyticsData?.ratio
                              )?.toLocaleString()}
                            </p>
                          </Stack>
                        </Grid>
                      </Grid>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          data={dateWisePurchaseVSales}
                          margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <XAxis
                            dataKey="PSPeriod"
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />
                          <YAxis
                            tickFormatter={(value) => {
                              return `${value?.toLocaleString()}`;
                            }}
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />
                          <CartesianGrid strokeDasharray="3 3" />

                          <Tooltip content={<RechartsCustomTooltip />} />
                          <Bar
                            dataKey="PSTotalPurchase"
                            fill={theme.palette.primary.main}
                            // name="Total Purchase"
                            barSize={15}
                          />
                          <Bar
                            dataKey="PSAvgVal"
                            fill={theme.palette.secondary.main}
                            // name="Average Value"
                            barSize={15}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </div>
                </Card>
              </Grid>
            )}
            {/* invoice  chart */}
            {features?.Invoice && (
              <Grid item xs={12}>
                {" "}
                <div className={classes.card_header}>
                  <p className="chart-caption">Invoice</p>
                  <IconButton
                    sx={{ p: 0 }}
                    ref={downloadBtnRefs[4]}
                    onClick={() => exportToPDF("invoice", 4)}
                  >
                    <FileDownloadIcon
                      sx={{ width: "0.8em", height: "0.8em" }}
                    />
                  </IconButton>
                </div>
                <Card>
                  <div
                    onClick={() => handleActiveChartSection("invoice")}
                    ref={chartRefs[4]}
                  >
                    <CardContent className="chart-container">
                      <Grid
                        container
                        spacing={0}
                        xs={8}
                        sm={7}
                        md={9}
                        lg={8}
                        className={classes.calculationContainer}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Invoice Count
                            </p>

                            <p className={classes.rightCalculationCaption}>
                              {customerCountAnalyticsData?.invoiceCount}
                            </p>
                          </Stack>
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Average Count
                            </p>
                            <p className={classes.rightCalculationCaption}>
                              {customerCountAnalyticsData?.averageCount}
                            </p>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Basket Value
                            </p>

                            <p className={classes.rightCalculationCaption}>
                              {" "}
                              {customerCountAnalyticsData?.basketValue}
                            </p>
                          </Stack>
                        </Grid>
                      </Grid>
                      <ResponsiveContainer width={"100%"} height={"100%"}>
                        <AreaChart
                          data={invoice}
                          margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                          }}
                          animationBegin={1000}
                          animationDuration={1000}
                        >
                          <XAxis
                            dataKey="Date"
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />
                          <YAxis
                            tickFormatter={(value) => {
                              return `${value?.toLocaleString()}`;
                            }}
                            // dataKey="CustCount"
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />

                          <Tooltip content={<RechartsCustomTooltip />} />
                          <Legend iconType="none" />
                          <CartesianGrid strokeDasharray="3 3" />

                          <Area
                            type="monotone"
                            dataKey="Date"
                            stackId="1"
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.primary.main}
                          />
                          <Area
                            type="monotone"
                            dataKey="CustCount"
                            stackId="1"
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.secondary.main}
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </div>
                </Card>
              </Grid>
            )}
            {/* stock  chart */}
            {features?.Stock && (
              <Grid item xs={12}>
                <div className={classes.card_header}>
                  <p className="chart-caption">Stock</p>
                  <IconButton
                    sx={{ p: 0 }}
                    ref={downloadBtnRefs[5]}
                    onClick={() => exportToPDF("stock", 5)}
                  >
                    <FileDownloadIcon
                      sx={{ width: "0.8em", height: "0.8em" }}
                    />
                  </IconButton>
                </div>
                <Card>
                  <div
                    onClick={() => handleActiveChartSection("stock")}
                    ref={chartRefs[5]}
                  >
                    <CardContent className="chart-container">
                      <Grid
                        container
                        spacing={0}
                        xs={8}
                        className={classes.calculationContainer}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Max Stock
                            </p>
                            <p className={classes.rightCalculationCaption}>
                              {stockAnalyticsData?.max}
                            </p>
                          </Stack>
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Min Stock
                            </p>

                            <p className={classes.rightCalculationCaption}>
                              {stockAnalyticsData?.min}
                            </p>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Avg Stock
                            </p>

                            <p className={classes.rightCalculationCaption}>
                              {" "}
                              {stockAnalyticsData?.avg}
                            </p>
                          </Stack>
                        </Grid>
                      </Grid>
                      <ResponsiveContainer width={"100%"} height={"100%"}>
                        <AreaChart
                          data={dailyStock}
                          margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                          }}
                        >
                          <XAxis
                            dataKey="Week"
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />
                          <YAxis
                            tickFormatter={(value) => {
                              return `${value?.toLocaleString()}`;
                            }}
                            // dataKey="StockVal"
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />

                          <Tooltip content={<RechartsCustomTooltip />} />
                          <Legend iconType="none" />
                          <CartesianGrid strokeDasharray="3 3" />

                          <Area
                            type="monotone"
                            dataKey="Week"
                            stackId="1"
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.primary.main}
                          />
                          <Area
                            type="monotone"
                            dataKey="StockVal"
                            stackId="1"
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.secondary.main}
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </div>
                </Card>
              </Grid>
            )}
            {/* sales loss */}
            {features?.SalesLoss && (
              <Grid item xs={12}>
                <div className={classes.card_header}>
                  <p className="chart-caption">Sales Loss</p>
                  <IconButton
                    sx={{ p: 0 }}
                    ref={downloadBtnRefs[8]}
                    onClick={() => exportToPDF("sales_loss", 8)}
                  >
                    <FileDownloadIcon
                      sx={{ width: "0.8em", height: "0.8em" }}
                    />
                  </IconButton>
                </div>
                <Card>
                  <div
                    onClick={() => handleActiveChartSection("salesLoss")}
                    ref={chartRefs[8]}
                  >
                    <CardContent className="chart-container">
                      <Grid
                        container
                        spacing={0}
                        xs={6}
                        sm={7}
                        md={8}
                        lg={7}
                        className={classes.calculationContainer}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Sales Loss
                            </p>

                            <p className={classes.rightCalculationCaption}>
                              {Number(
                                salesLossAnalyticalData?.sales
                              )?.toLocaleString()}
                            </p>
                          </Stack>
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              Cogs Loss
                            </p>
                            <p className={classes.rightCalculationCaption}>
                              {Number(
                                salesLossAnalyticalData?.cogs
                              )?.toLocaleString()}
                            </p>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              GP Loss
                            </p>

                            <p className={classes.rightCalculationCaption}>
                              {Number(
                                salesLossAnalyticalData?.gp
                              ).toLocaleString()}
                            </p>
                          </Stack>
                          <Stack
                            direction="row"
                            sx={{ height: "15px !important" }}
                          >
                            <p className={classes.leftCalculationCaption}>
                              GP Loss %
                            </p>
                            <p className={classes.rightCalculationCaption}>
                              {Number(
                                salesLossAnalyticalData?.gpPercentage
                              )?.toLocaleString() + " % "}
                            </p>
                          </Stack>
                        </Grid>
                      </Grid>
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                          data={salesLoss}
                          margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <XAxis
                            dataKey="Week"
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />
                          <YAxis
                            tickFormatter={(value) => {
                              return `${value?.toLocaleString()}`;
                            }}
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />
                          <Tooltip content={<RechartsCustomTooltip />} />
                          <Legend iconType="none" />
                          <CartesianGrid strokeDasharray="3 3" />

                          <Area
                            dataKey="SalLossAmt"
                            stackId="a"
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.secondary.main}
                          />
                          <Area
                            dataKey="SalLossCOGS"
                            stackId="a"
                            stroke={theme.palette.primary.main}
                            fill="#8884d8"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </div>
                </Card>
              </Grid>
            )}
            {/* hourly sales */}
            {features?.HourlySales && (
              <Grid item xs={12}>
                <div className={classes.card_header}>
                  <p className="chart-caption">Hourly Sales</p>
                  <IconButton
                    sx={{ p: 0 }}
                    ref={downloadBtnRefs[6]}
                    onClick={() => exportToPDF("hourly_sales", 6)}
                  >
                    <FileDownloadIcon
                      sx={{ width: "0.8em", height: "0.8em" }}
                    />
                  </IconButton>
                </div>
                <Card>
                  <div
                    onClick={() => handleActiveChartSection("hourlySales")}
                    ref={chartRefs[6]}
                  >
                    <CardContent className="chart-container">
                      <Grid
                        container
                        spacing={0}
                        xs={12}
                        className={classes.calculationContainer}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "20px !important" }}
                          >
                            <div
                              style={{
                                width: "50%",
                                display: "flex",
                              }}
                            >
                              <p
                                style={{
                                  width: "90%",
                                  margin: "auto",
                                  marginLeft: "10px",
                                }}
                              >
                                Amount
                              </p>
                            </div>
                            <p style={{ width: "50%", margin: "auto" }}>
                              {hourAnalyticsData?.AmtLc?.toLocaleString()}
                            </p>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.chart_header}
                        >
                          <Stack
                            direction="row"
                            sx={{ height: "20px !important" }}
                          >
                            <div
                              style={{
                                width: "50%",
                                display: "flex",
                              }}
                            >
                              <p
                                style={{
                                  width: "90%",
                                  margin: "auto",
                                  marginLeft: "10px",
                                }}
                              >
                                Count
                              </p>
                            </div>
                            <p style={{ width: "50%", margin: "auto" }}>
                              {hourAnalyticsData?.Count?.toLocaleString()}
                            </p>
                          </Stack>
                        </Grid>
                      </Grid>

                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                          data={hourlySales}
                          margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <XAxis
                            dataKey="Hour"
                            tick={{
                              fontSize: 2,
                              fill: "black",
                            }}
                          />
                          <YAxis
                            tickFormatter={(value) => {
                              return `${value?.toLocaleString()}`;
                            }}
                            // dataKey=""
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />
                          <Tooltip content={<RechartsCustomTooltip />} />
                          <Legend iconType="none" />
                          <CartesianGrid strokeDasharray="3 3" />

                          <Area
                            dataKey="Hour"
                            stackId="a"
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.primary.main}
                          />
                          <Area
                            dataKey="AvgAmtLc"
                            stackId="a"
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.secondary.main}
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </div>
                </Card>
              </Grid>
            )}
            {/* hourly count */}
            {features?.HourlyCount && (
              <Grid item xs={12}>
                <div className={classes.card_header}>
                  <p className="chart-caption">Hourly Count</p>
                  <IconButton
                    sx={{ p: 0 }}
                    ref={downloadBtnRefs[7]}
                    onClick={() => exportToPDF("hourly_count", 7)}
                  >
                    <FileDownloadIcon
                      sx={{ width: "0.8em", height: "0.8em" }}
                    />
                  </IconButton>
                </div>
                <Card>
                  <div
                    onClick={() => handleActiveChartSection("hourlyCount")}
                    ref={chartRefs[7]}
                  >
                    <CardContent className="chart-container">
                      <Grid
                        container
                        spacing={0}
                        sm={10}
                        className={classes.calculationContainer}
                      >
                        <Grid
                          container
                          spacing={0}
                          xs={12}
                          className={classes.calculationContainer}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            className={classes.chart_header}
                          >
                            <Stack
                              direction="row"
                              sx={{ height: "20px !important" }}
                            >
                              <div
                                style={{
                                  width: "50%",
                                  display: "flex",
                                }}
                              >
                                <p
                                  style={{
                                    width: "90%",
                                    margin: "auto",
                                    marginLeft: "10px",
                                  }}
                                >
                                  Amount
                                </p>
                              </div>
                              <p style={{ width: "50%", margin: "auto" }}>
                                {hourAnalyticsData?.AmtLc?.toLocaleString()}
                              </p>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            className={classes.chart_header}
                          >
                            <Stack
                              direction="row"
                              sx={{ height: "20px !important" }}
                            >
                              <div
                                style={{
                                  width: "50%",
                                  display: "flex",
                                }}
                              >
                                <p
                                  style={{
                                    width: "90%",
                                    margin: "auto",
                                    marginLeft: "10px",
                                  }}
                                >
                                  Count
                                </p>
                              </div>
                              <p style={{ width: "50%", margin: "auto" }}>
                                {hourAnalyticsData?.Count?.toLocaleString()}
                              </p>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                          data={hourlySales}
                          margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <XAxis
                            dataKey="Hour"
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />
                          <YAxis
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />
                          <Tooltip content={<RechartsCustomTooltip />} />
                          <Legend iconType="none" />
                          <CartesianGrid strokeDasharray="3 3" />

                          <Area
                            dataKey="Hour"
                            stackId="a"
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.primary.main}
                          />
                          <Area
                            dataKey="AvgCount"
                            stackId="a"
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.secondary.main}
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </div>
                </Card>
              </Grid>
            )}
            {/* {BranchWiseStock} */}
            {features?.BranchWiseStock && (
              <Grid item xs={12}>
                <div className={classes.card_header}>
                  <p className="chart-caption">Branch Wise Stock</p>
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={() => exportToPDF("branch_wise_stock", 9)}
                    ref={downloadBtnRefs[9]}
                  >
                    <FileDownloadIcon
                      sx={{ width: "0.8em", height: "0.8em" }}
                    />
                  </IconButton>
                </div>
                <Card>
                  <div
                    onClick={() => handleActiveChartSection("BranchWiseStock")}
                    ref={chartRefs[9]}
                  >
                    <CardContent className="chart-container">
                      <ResponsiveContainer width={"100%"} height={"100%"}>
                        <BarChart
                          data={branchWiseStockData}
                          margin={{
                            top: 5,
                            right: 10,
                            left: 10,
                            bottom: 5,
                          }}
                          animationBegin={1000}
                          animationDuration={1000}
                        >
                          <XAxis
                            dataKey="Name"
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />
                          <YAxis
                            tickFormatter={(value) => {
                              return `${value?.toLocaleString()}`;
                            }}
                            dataKey="StockVal"
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />

                          <CartesianGrid strokeDasharray="3 3" />

                          {branchWiseStockData?.map((entry, index) => (
                            <Label
                              key={`label-${index}`}
                              value={entry.StockQty}
                              position="top"
                              fill="#888"
                            />
                          ))}
                          <Bar
                            dataKey="Name"
                            barSize={15}
                            fill={theme.palette.primary.main}
                          />
                          <Bar
                            dataKey="StockVal"
                            barSize={15}
                            fill={theme.palette.primary.main}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </div>
                </Card>
              </Grid>
            )}
            {/* {departmentWiseStock } */}
            {features?.DepartmentWiseStock && (
              <Grid item xs={12}>
                <div className={classes.card_header}>
                  <p className="chart-caption">Department Wise Stock</p>
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={() => exportToPDF("DepartmentWiseStock", 10)}
                    ref={downloadBtnRefs[10]}
                  >
                    <FileDownloadIcon
                      sx={{ width: "0.8em", height: "0.8em" }}
                    />
                  </IconButton>
                </div>
                <Card>
                  <div
                    onClick={() => handleActiveChartSection("DepartmentWiseStock")}
                    ref={chartRefs[10]}
                  >
                    <CardContent className="chart-container">
                      <ResponsiveContainer width={"100%"} height={"100%"}>
                        <BarChart
                          data={deptWiseStockData}
                          margin={{
                            top: 5,
                            right: 10,
                            left: 10,
                            bottom: 5,
                          }}
                          animationBegin={1000}
                          animationDuration={1000}
                        >
                          <XAxis
                            dataKey="Name"
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />
                          <YAxis
                            tickFormatter={(value) => {
                              return `${value?.toLocaleString()}`;
                            }}
                            dataKey="StockVal"
                            tick={{
                              fontSize: 10,
                              fill: "black",
                            }}
                          />

                          <CartesianGrid strokeDasharray="3 3" />

                          {deptWiseStockData?.map((entry, index) => (
                            <Label
                              key={`label-${index}`}
                              value={entry.StockQty}
                              position="top"
                              fill="#888"
                            />
                          ))}
                          {/* <Legend iconType="none" />
                                              <CartesianGrid strokeDasharray="3 3" />
*/}
                          <Bar
                            dataKey="Name"
                            barSize={15}
                            fill={theme.palette.primary.main}
                          />
                          <Bar
                            dataKey="StockVal"
                            barSize={15}
                            fill={theme.palette.primary.main}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </div>
                </Card>
              </Grid>
            )}
            {/* inventory */}
            {features?.InventoryVelocity && (
              <Grid item xs={12}>
                <div className={classes.card_header}>
                  <p className="chart-caption">Inventory Velocity</p>
                  <IconButton
                    sx={{ p: 0 }}
                    ref={downloadBtnRefs[11]}
                    onClick={() => exportToPDF("sales_loss", 11)}
                  >
                    <FileDownloadIcon
                      sx={{ width: "0.8em", height: "0.8em" }}
                    />
                  </IconButton>
                </div>
                <Card>
                  <div
                    onClick={() => handleActiveChartSection("inventory")}
                    ref={chartRefs[11]}
                  >
                    <CardContent
                      className="chart-container"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div>Inventory</div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            class="circle circle1"
                          >
                            {inventoryChartData && inventoryChartData[0]?.value?.toLocaleString()}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div>Velocity</div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            class="circle circle2"
                          >
                            {" "}
                            {inventoryChartData && inventoryChartData[1]?.value?.toLocaleString()}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div>Projection</div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            class="circle circle3"
                          >
                            {" "}
                            {inventoryChartData && inventoryChartData[2]?.value?.toLocaleString()}
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </div>
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* Third Section */}

        {screenSize > 900 ? (
          <>
            {
              <TableComponent
                data={data}
                activeChartSection={activeChartSectionFooter}
              />
            }
          </>
        ) : (
          <>
            {
              <Modal
                heading={chartTitle}
                open={open}
                handleCloseModal={handleCloseModal}
              >
                <TableComponent
                  data={data}
                  activeChartSection={activeChartSectionFooter}
                />
              </Modal>
            }
          </>
        )}
      </Grid>
    </Box>
  );
};

export default Dashboard;
