import { Button } from "@mui/material";

const ButtonComp = ({ name, type,size, variant, disabled=false, color,style,clickEvent }) => {
  return(
    <Button sx={style} color={color} size={size} type={type} variant={variant?variant:null} disabled={disabled} onClick={clickEvent}>
    {name}
  </Button>
  )  

};

export default ButtonComp;
