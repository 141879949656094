import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { AppBar } from "@material-ui/core";
import theme from "../../theme/theme";

const Modal = ({ heading, children, open, handleCloseModal }) => {
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="custom-modal-title"
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <DialogTitle
              id="custom-modal-title"
              sx={{
                margin: "auto",
                fontSize: "15px",
                padding: "0px !important",
              }}
            >
              {heading}{" "}
            </DialogTitle>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent
          sx={{
            border: `1px solid ${theme.palette.primary.main}`,
            padding: "20px 0px",
          }}
        >
          {children}{" "}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Modal;
