// App.js
import "./App.css";
import Layout from "./pages/layout/layout";
import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { PrivateRoute } from "./Utility/PrivateRoute";
// components
import Login from "./pages/login/login";
import Dashboard from "./pages/dashboard/Dashboard";
// toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { CssBaseline } from "@mui/material";
import { persistor, store } from "./redux/store";
//material ui theme
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import Spinner from "./components/spinner/spinner";

import InstaIntegration from "./pages/InstaIntegration/InstaIntegration";
import CustomerCode from "./Saqer/Customer-Code/CustomerCode";
import CustomerDetails from "./Saqer/Customer-Details/customerDetails";
import Success from "./Saqer/Success/Success";
import Landing from "./Saqer/Landing-Page/Landing";
import CustomerPage from "./Saqer/Customer-page/CustomerPage";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div id="loader" style={{ display: "none" }}>
            <Spinner />
          </div>
          <BrowserRouter>
            <Routes>
              {/* MIS WEB ROUTES */}

              <Route
                path="login"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Login />
                  </Suspense>
                }
              />
              <Route path="/" element={<Layout />}>
                <Route path="/" element={<Navigate to="/home" replace />} />
                <Route
                  path="home"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route
                path="instaIntegration"
                element={
                  <PrivateRoute>
                    <InstaIntegration />
                  </PrivateRoute>
                }
              />
              {/* MIS WEB ROUTES ENDS */}

              {/* SAQER ROUTES STARTS */}

              <Route
                path="simplified/:Loc"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Landing />
                  </Suspense>
                }
              />
              <Route
                path="simplified"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Landing />
                  </Suspense>
                }
              />
               <Route
                path="customer-page"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <CustomerPage />
                  </Suspense>
                }
              />

              <Route
                path="customer-code"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <CustomerCode />
                  </Suspense>
                }
              />
              <Route
                path="customer-details"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <CustomerDetails />
                  </Suspense>
                }
              />
              <Route
                path="success-page"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Success />
                  </Suspense>
                }
              />

              {/* SAQER ROUTES ENDS */}
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
