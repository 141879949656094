import { Box, Grid } from "@material-ui/core";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridPagination,
  GridFooter,
  GridToolbar,
} from "@mui/x-data-grid";
let deptWiseSalesSum,
  branchWiseSalesDataSum,
  dateWisePurchaseVSalesSum,
  salesVsCogsSum,
  hourlySalesSum,
  InvoiceSums,
  SalesLossSum,
  stock;
export const bindDep = (deptWiseSales) => {
  deptWiseSalesSum = deptWiseSales?.reduce(
    (acc, obj) => {
      acc.Markup += obj.Markup || 0;
      acc.SalesQty += obj.SalesQty || 0;
      acc.Sales += obj.Sales || 0;
      acc.COGS += obj.COGS || 0;
      acc.GP += obj.GP || 0;

      return acc;
    },
    {
      Markup: 0,
      SalesQty: 0,
      Sales: 0,
      COGS: 0,
      GP: 0,
    }
  );
  const DepartmentWiseSales = [
    {
      field: "Department",
      headerName: "Department",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",
      flex: 30,
    },

    {
      field: "Sales",
      headerName: "Sales",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",
      align: "right",
      headerAlign: "right",

      flex: 20,
      // footer: `${deptWiseSalesSum?.Sales?.toFixed(0)?.toLocaleString()}`,
    },
    {
      field: "COGS",
      headerName: "COGS",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",
      align: "right",
      headerAlign: "right",

      flex: 20,
      // footer: `${deptWiseSalesSum?.COGS?.toFixed(0)?.toLocaleString()}`,
    },
    {
      field: "GP",
      headerName: "GP",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",
      align: "right",
      headerAlign: "right",

      flex: 15,
      // footer: `${(deptWiseSalesSum?.GP / deptWiseSalesSum?.Sales)?.toFixed(2)}`,
    },
    {
      field: "GPPer",
      headerName: "GP.%",
      align: "right",
      headerAlign: "right",

      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",
      flex: 15,
      // footer: `${(
      //   (deptWiseSalesSum?.GP / deptWiseSalesSum?.Sales) *
      //   100
      // )?.toFixed(2)}`,
    },
  ];
  return DepartmentWiseSales;
};
export const bindBranch = (branchWiseSalesData) => {
  branchWiseSalesDataSum = branchWiseSalesData?.reduce(
    (acc, obj) => {
      acc.BrSales += obj.BrSales || 0;
      acc.Markup1 += obj.Markup1 || 0;
      acc.BrGP += obj.BrGP || 0;
      acc.BrCOGS += obj.BrCOGS || 0;
      return acc;
    },
    {
      BrSales: 0,
      BrGP: 0,
      Markup1: 0,
      BrCOGS: 0,
    }
  );
  const BranchWiseSales = [
    {
      field: "Branch",
      headerName: "Branch",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 120,
      // footer: `Total`,
    },
    {
      field: "BrSales",
      headerName: "Sales",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return 100;
      },
      flex: 80,
      // footer: `${branchWiseSalesDataSum?.BrSales?.toFixed(0)?.toLocaleString()}`,
    },
    {
      field: "BrCOGS",
      headerName: "COGS",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${branchWiseSalesDataSum?.BrCOGS?.toFixed(0)?.toLocaleString()}`,
    },
    {
      field: "BrGP",
      headerName: "GP",
      // footer: `${branchWiseSalesDataSum?.BrGP?.toFixed(0)?.toLocaleString()}`,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 30,
    },

    {
      field: "BrGPPer",
      headerName: "GP.%",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 30,
      // footer: `${(
      // (branchWiseSalesDataSum?.BrGP / branchWiseSalesDataSum?.BrSales) *
      //   100
      // )?.toFixed(2)}`,
    },
    {
      field: "BrMarkup",
      headerName: "Markup",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${(
      //   (branchWiseSalesDataSum?.BrGP / branchWiseSalesDataSum?.BrCOGS) *
      //   100
      // )?.toFixed(2)}`,
    },
  ];

  return BranchWiseSales;
};
export const bindPurchaseVSales = (dateWisePurchaseVSales) => {
  dateWisePurchaseVSalesSum = dateWisePurchaseVSales?.reduce(
    (acc, obj) => {
      acc.PSBaseQty += obj.PSBaseQty || 0;
      acc.PSAvgVal += obj.PSAvgVal || 0;
      acc.PSPurchaseQty += obj.PSPurchaseQty || 0;
      acc.PSPurchaseValue += obj.PSPurchaseValue || 0;
      acc.PSTransferQty += obj.PSTransferQty || 0;
      acc.PSTransferValue += obj.PSTransferValue || 0;
      acc.PSCreditNote += obj.PSCreditNote || 0;
      acc.PSTotalPurchase += obj.PSTotalPurchase || 0;

      return acc;
    },
    {
      PSBaseQty: 0,
      PSAvgVal: 0,
      PSPurchaseQty: 0,
      PSPurchaseValue: 0,
      PSTransferQty: 0,
      PSTransferValue: 0,
      PSTotalPurchase: 0,
    }
  );
  const purchaseVSales = [
    {
      headerName: "Period",
      field: "PSPeriod",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",
      flex: 80,
    },
    {
      headerName: "BaseQty",
      field: "PSBaseQty",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${dateWisePurchaseVSalesSum?.PSBaseQty?.toFixed(
      //   0
      // )?.toLocaleString()}`,
    },
    {
      headerName: "AvgVal",
      field: "PSAvgVal",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${dateWisePurchaseVSalesSum?.PSAvgVal?.toFixed(
      //   0
      // )?.toLocaleString()}`,
    },
    {
      headerName: "PurchaseQty",
      field: "PSPurchaseQty",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${dateWisePurchaseVSalesSum?.PSPurchaseQty?.toFixed(
      //   0
      // )?.toLocaleString()}`,
    },
    {
      headerName: "PurchaseValue",
      field: "PSPurchaseValue",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${dateWisePurchaseVSalesSum?.PSPurchaseValue?.toFixed(
      //   0
      // )?.toLocaleString()}`,
    },
    {
      headerName: "TransferQty",
      field: "PSTransferQty",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${dateWisePurchaseVSalesSum?.PSTransferQty?.toFixed(
      //   0
      // )?.toLocaleString()}`,
    },
    {
      headerName: "TransferValue",
      field: "PSTransferValue",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${dateWisePurchaseVSalesSum?.PSTransferValue?.toFixed(
      //   0
      // )?.toLocaleString()}`,
    },
    {
      headerName: "CreditNote",
      field: "PSCreditNote",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${dateWisePurchaseVSalesSum?.PSCreditNote?.toFixed(
      //   0
      // )?.toLocaleString()}`,
    },
    {
      headerName: "TotalPurchase",
      field: "PSTotalPurchase",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${dateWisePurchaseVSalesSum?.PSTotalPurchase?.toFixed(
      //   0
      // )?.toLocaleString()}`,
    },
  ];
  return purchaseVSales;
};
export const bindSalesVCogs = (data) => {
  salesVsCogsSum = data?.reduce(
    (acc, obj) => {
      acc.PostDt += obj.PostDt || 0;
      acc.GrossAmt += obj.DtGrossAmt || 0;
      acc.LDiscAmt += obj.DtLDiscAmt || 0;
      acc.TDiscAmt += obj.DtTDiscAmt || 0;
      acc.FocAmt += obj.DtFocAmt || 0;
      acc.Rounding += obj.DtRounding || 0;
      acc.LandVal += obj.DtLandVal || 0;
      acc.AvgVal += obj.DtAvgVal || 0;
      acc.DiscAmt += obj.DtDiscAmt || 0;
      acc.GP += obj.DtGP || 0;
      return acc;
    },
    {
      PostDt: 0,
      DtGrossAmt: 0,
      DtLDiscAmt: 0,
      DtTDiscAmt: 0,
      DtFocAmt: 0,
      DtRounding: 0,
      DtLandVal: 0,
      DtAvgVal: 0,
      DtDiscAmt: 0,
      DtGP: 0,
    }
  );
  const SalesVCogs = [
    {
      headerName: "Date",
      field: "PostDt",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `Total`,
    },
    {
      headerName: "Gross Sales",
      field: "DtGrossAmt",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${salesVsCogsSum?.DtGrossAmt?.toFixed(0)?.toLocaleString()}`,
    },
    {
      headerName: "Discount",
      field: "DtDiscAmt",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${salesVsCogsSum?.DtDiscAmt?.toFixed(0)?.toLocaleString()}`,
    },
    {
      headerName: "FocAmt",
      field: "DtFocAmt",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${salesVsCogsSum?.DtFocAmt?.toFixed(0)?.toLocaleString()}`,
    },
    {
      headerName: "Net Sales",
      field: "DtLandVal",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${salesVsCogsSum?.DtLandVal?.toFixed(0)?.toLocaleString()}`,
    },
    {
      headerName: "COGS",
      field: "DtAvgVal",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${salesVsCogsSum?.DtAvgVal?.toFixed(0)?.toLocaleString()}`,
    },
    {
      headerName: "GP",
      field: "DtGP",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${salesVsCogsSum?.DtGP?.toFixed(0)?.toLocaleString()}`,
    },
    {
      headerName: "GPPerc",
      field: "DtGPPerc",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      footer: `${salesVsCogsSum?.GP?.toFixed(0)?.toLocaleString()}`,
    },
  ];
  return SalesVCogs;
};
export const bindHourlySalesColumns = (hourlySales) => {
  hourlySalesSum = hourlySales?.reduce(
    (acc, obj) => {
      acc.Hour += obj.Hour || 0;
      acc.Count += obj.Count || 0;
      acc.AvgCount += obj.AvgCount || 0;
      acc.AmtLc += obj.AmtLc || 0;
      acc.AvgAmtLc += obj.AvgAmtLc || 0;

      return acc;
    },
    {
      Hour: 0,
      Count: 0,
      AvgCount: 0,
      AmtLc: 0,
      AvgAmtLc: 0,
    }
  );
  const hourlySalesColumns = [
    {
      headerName: "Hour",
      field: "Hour",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${hourlySalesSum?.Hour?.toFixed(0)?.toLocaleString()}`,
    },
    {
      headerName: "Count",
      field: "Count",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${hourlySalesSum?.Count?.toFixed(0)?.toLocaleString()}`,
    },
    {
      headerName: "AvgCount",
      field: "AvgCount",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${hourlySalesSum?.AvgCount?.toFixed(0)?.toLocaleString()}`,
    },
    {
      headerName: "AmtLc",
      field: "AmtLc",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${hourlySalesSum?.AmtLc?.toFixed(0)?.toLocaleString()}`,
    },
    {
      headerName: "AvgAmtLc",
      field: "AvgAmtLc",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${hourlySalesSum?.AvgAmtLc?.toFixed(0)?.toLocaleString()}`,
    },
  ];
  return hourlySalesColumns;
};
export const bindHourlyCount = () => {
  const hourlyCount = [];
  return hourlyCount;
};
export const bindInvoiceColumns = (data) => {
  InvoiceSums = data?.reduce(
    (acc, obj) => {
      acc.CustCount += obj.CustCount || 0;
      acc.Amt += obj.Amt || 0;
      acc.BasketValue += obj.BasketValue || 0;
      acc.LoyaltyCustomer += obj.LoyaltyCustomer || 0;
      acc.CashCustomer += obj.CashCustomer || 0;
      return acc;
    },
    {
      CustCount: 0,
      Amt: 0,
      BasketValue: 0,
      LoyaltyCustomer: 0,
      CashCustomer: 0,
    }
  );
  const invoiceColumns = [
    {
      heder: "Week",
      field: "Week",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `Total`,
    },
    {
      heder: "Date",
      field: "Date",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
    },
    {
      heder: "CustCount",
      field: "CustCount",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${InvoiceSums?.CustCount?.toFixed(0)?.toLocaleString()}`,
    },
    {
      heder: "Amt",
      field: "Amt",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${InvoiceSums?.Amt?.toFixed(0)?.toLocaleString()}`,
    },
    {
      heder: "BasketValue",
      field: "BasketValue",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${InvoiceSums?.BasketValue?.toFixed(0)?.toLocaleString()}`,
    },
    {
      heder: "LoyaltyCustomer",
      field: "LoyaltyCustomer",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${InvoiceSums?.LoyaltyCustomer?.toFixed(0)?.toLocaleString()}`,
    },
    {
      heder: "CashCustomer",
      field: "CashCustomer",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${InvoiceSums?.CashCustomer?.toFixed(0)?.toLocaleString()}`,
    },
  ];
  return invoiceColumns;
};
export const bindSalesLossColumns = (data) => {
  SalesLossSum = data?.reduce(
    (acc, obj) => {
      acc.SalLossAmt += obj.SalLossAmt || 0;
      acc.SalLossCOGS += obj.SalLossCOGS || 0;
      return acc;
    },
    {
      PostDt: 0,
      SalLossAmt: 0,
      SalLossCOGS: 0,
    }
  );
  const salesColumn = [
    {
      headerName: "Week",
      field: "Week",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `Total`,
    },
    {
      headerName: "PostDt",
      field: "PostDt",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
    },
    {
      headerName: "SalLossAmt",
      field: "SalLossAmt",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${SalesLossSum?.SalLossAmt?.toFixed(0)?.toLocaleString()}`,
    },
    {
      headerName: "SalLossCOGS",
      field: "SalLossCOGS",
      disableColumnMenu: true,
      sortable: false,
      cellClassName: "cell-fs",

      flex: 80,
      // footer: `${SalesLossSum?.SalLossCOGS?.toFixed(0)?.toLocaleString()}`,
    },
  ];
  return salesColumn;
};
export const bindStockColumns = () => {
  stock = [];
  return stock;
};

export const branchFooter = () => {
  return (
    <>
      {" "}
      <Grid container spacing={1} justifyContent="space-evenly">
        <Grid item>{"Total"}</Grid>
        <Grid item>
          {branchWiseSalesDataSum &&
            branchWiseSalesDataSum?.SalesQty?.toFixed(0)?.toLocaleString()}
        </Grid>
        <Grid item>
          {branchWiseSalesDataSum &&
            branchWiseSalesDataSum?.Sales?.toFixed(0)?.toLocaleString()}
        </Grid>
        <Grid item>
          {branchWiseSalesDataSum &&
            branchWiseSalesDataSum?.COGS?.toFixed(0)?.toLocaleString()}
        </Grid>
        <Grid item>
          {(
            branchWiseSalesDataSum &&
            branchWiseSalesDataSum?.GP / branchWiseSalesDataSum &&
            branchWiseSalesDataSum?.Sales
          )?.toFixed(2)}
        </Grid>
        <Grid item></Grid>
      </Grid>
    </>
  );
};
export const depFooter = () => {
  return (
    <>
      {" "}
      <Grid container spacing={1} justifyContent="space-evenly">
        <Grid item>{"Total"}</Grid>

        <Grid item>
          {deptWiseSalesSum &&
            deptWiseSalesSum?.Sales?.toFixed(0)?.toLocaleString()}
        </Grid>
        <Grid item>
          {deptWiseSalesSum &&
            deptWiseSalesSum?.COGS?.toFixed(0)?.toLocaleString()}
        </Grid>
        <Grid item>
          {(
            deptWiseSalesSum &&
            deptWiseSalesSum?.GP / deptWiseSalesSum &&
            deptWiseSalesSum?.Sales
          )?.toFixed(2)}
        </Grid>
        <Grid item></Grid>
      </Grid>
    </>
  );
};
export const salesVSpurchaseFooter = () => {
  return (
    <>
      {" "}
      <Grid container spacing={1} justifyContent="space-evenly">
        {/* <Grid item>
          {" "}
          {deptWiseSalesSum &&
            dateWisePurchaseVSalesSum?.PSBaseQty?.toFixed(0)?.toLocaleString()}
        </Grid> */}
        <Grid item>
          {" "}
          {deptWiseSalesSum &&
            dateWisePurchaseVSalesSum?.PSAvgVal?.toFixed(0)?.toLocaleString()}
        </Grid>
        <Grid item>
          {deptWiseSalesSum &&
            dateWisePurchaseVSalesSum?.PSPurchaseQty?.toFixed(
              0
            )?.toLocaleString()}
        </Grid>
        <Grid item>
          {deptWiseSalesSum &&
            dateWisePurchaseVSalesSum?.PSPurchaseValue?.toFixed(
              0
            )?.toLocaleString()}
        </Grid>
        <Grid item>
          {" "}
          {deptWiseSalesSum &&
            dateWisePurchaseVSalesSum?.PSTransferQty?.toFixed(
              0
            )?.toLocaleString()}
        </Grid>
        <Grid item>
          {" "}
          {deptWiseSalesSum &&
            dateWisePurchaseVSalesSum?.PSTransferValue?.toFixed(
              0
            )?.toLocaleString()}
        </Grid>
        <Grid item>
          {" "}
          {deptWiseSalesSum &&
            dateWisePurchaseVSalesSum?.PSCreditNote?.toFixed(
              0
            )?.toLocaleString()}
        </Grid>
        <Grid item>
          {" "}
          {deptWiseSalesSum &&
            dateWisePurchaseVSalesSum?.PSTotalPurchase?.toFixed(
              0
            )?.toLocaleString()}
        </Grid>
      </Grid>
    </>
  );
};
export const salesVsCogsFooter = () => {
  return (
    <>
      {" "}
      <Grid container spacing={1} justifyContent="space-evenly">
        <Grid item>
          {" "}
          {salesVsCogsSum &&
            salesVsCogsSum?.DtGrossAmt?.toFixed(0)?.toLocaleString()}
        </Grid>
        <Grid item>
          {" "}
          {salesVsCogsSum &&
            salesVsCogsSum?.DtDiscAmt?.toFixed(0)?.toLocaleString()}
        </Grid>
        <Grid item>
          {salesVsCogsSum &&
            salesVsCogsSum?.DtFocAmt?.toFixed(0)?.toLocaleString()}
        </Grid>
        <Grid item>
          {salesVsCogsSum &&
            salesVsCogsSum?.DtLandVal?.toFixed(0)?.toLocaleString()}
        </Grid>
        <Grid item>
          {" "}
          {salesVsCogsSum &&
            salesVsCogsSum?.DtAvgVal?.toFixed(0)?.toLocaleString()}
        </Grid>
        <Grid item>
          {" "}
          {salesVsCogsSum && salesVsCogsSum?.DtGP?.toFixed(0)?.toLocaleString()}
        </Grid>
        <Grid item>
          {" "}
          {salesVsCogsSum && salesVsCogsSum?.GP?.toFixed(0)?.toLocaleString()}
        </Grid>
        {/* <Grid item>
          {" "}
          {dateWisePurchaseVSalesSum?.PSTotalPurchase?.toFixed(
            0
          )?.toLocaleString()}
        </Grid> */}
      </Grid>
    </>
  );
};
export const invoiceFooter = () => {
  return (
    <>
      {" "}
      <Grid container spacing={1} justifyContent="space-evenly">
        <Grid item>{1000}</Grid>
        <Grid item>{1000}</Grid>
        <Grid item>{1000}</Grid>
        <Grid item>{1000}</Grid>
        <Grid item>{1000}</Grid>
        <Grid item>{1000}</Grid>
      </Grid>
    </>
  );
};
export const stockFooter = () => {
  return (
    <>
      {" "}
      <Grid container spacing={1} justifyContent="space-evenly">
        <Grid item>{1000}</Grid>
        <Grid item>{1000}</Grid>
        <Grid item>{1000}</Grid>
        <Grid item>{1000}</Grid>
        <Grid item>{1000}</Grid>
        <Grid item>{1000}</Grid>
      </Grid>
    </>
  );
};
export const HourlyFooter = () => {
  return (
    <>
      {" "}
      <Grid container spacing={1} justifyContent="space-evenly">
        <Grid item>{1000}</Grid>
        <Grid item>{1000}</Grid>
        <Grid item>{1000}</Grid>
        <Grid item>{1000}</Grid>
        <Grid item>{1000}</Grid>
        <Grid item>{1000}</Grid>
      </Grid>
    </>
  );
};
