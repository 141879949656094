import React from "react";
import Sidebar from "../../components/layout/sidebar/sidebar";
import theme from "../../theme/theme";
const Layout = () => {
  return (
    <>
      <div
        className="custom-scrollbar"
        style={{
          backgroundColor: theme.palette.secondary.main,
          padding:" 5px 15px",
          height: "100vh",
          overflow: "auto"
       }}
      >
        <Sidebar />
      </div>
    </>
  );
};

export default Layout;
