import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  userId: "",
  userName: "",
  clientId: "",
  password: "",
  rememberMe: false,
  ecommlist: [],
  instaloc: [],
  menuItem: [
    {
      id: 1,
      name: "Insta Integration",
      route: "/instaIntegration",
      type: "INSTA",
      disabled: false,
    },
    {
      id: 2,
      name: "Logout",
      route: "/login",
      type: "LOGOUT",
      disabled: false,
    },
  ],
  isLoggedIn: false,
  feature: {},
};

const loginSlice = createSlice({
  name: "loginSlice",
  initialState: initialState,

  reducers: {
    loginSuccess: (state, action) => {
      return { ...state, ...action.payload, isLoggedIn: !state.isLoggedIn };
    },
    Logout: (state) => {
      state.isLoggedIn = !state.isLoggedIn;
    },

    setLoginData: (state, action) => {
      return { ...state, ...action.payload };
    },
    setMenuItem: (state, action) => {
      state.menuItem = action.payload;
    },
    setFeatureObj: (state, action) => {
      state.feature = action.payload;
    },
  },
});

export const { loginSuccess, Logout, setMenuItem, setFeatureObj } =
  loginSlice.actions;
export default loginSlice.reducer;
