import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import CustomerCode from "../Customer-Code/CustomerCode";
import CustomerDetails from "../Customer-Details/customerDetails";

// import { useTheme } from "@mui/styles";
// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";

const CustomerPage = () => {
  // const theme = useTheme();
  // const classes = useStyles();
  // const navigate = useNavigate();
  const [formData, setFormData] = useState({
    customerCode: "",
    firstName: "",
    lastName: "",
    gender: "",
    nationality: "",
    mobile: "",
    email: "",
    type: "",
    UId: "",
    refNo: "",
  });

  return (
    <Box>
      {formData.customerCode == "" ? (
        <CustomerCode xsetFormData={setFormData} />
      ) : (
        <CustomerDetails setFormData={setFormData} formData={formData} />
      )}
    </Box>
  );
};

export default CustomerPage;
