import { fExecuteAPI } from "../../Server/Apis";
import { fAlertToast } from "../../Utility/Utilitys";

const getLogin = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI("GET_API_LOGIN", xObj);
    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};
const checkInstaAvailability = (data, menus) => {
  const isExist = data?.some((val) => val?.Typ === "INSTA");
  const formattedData = menus?.map((val) => {
    if (val?.type === "INSTA")
      return {
        ...val,
        disabled: !isExist,
      };
    else return val;
  });
  return formattedData;
};
export { getLogin, checkInstaAvailability };
