import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  screenSize: "",
};

const sidebarSlice = createSlice({
  name: "sidebarSlice",
  initialState,
  reducers: {
    setResponsiveSize: (state, action) => {
      return { ...state, screenSize: action.payload };
    },
  },
});

export const { setResponsiveSize } = sidebarSlice.actions;

export default sidebarSlice.reducer;
