import { fExecuteAPI } from "../../Server/Apis";
import { fAlertToast } from "../../Utility/Utilitys";

const getThirdPartyLogin = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI("POST_API_THIRD_PARTY_LOGIN", xObj);
    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};
const getWebInstaItems = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI("POST_API_WEB_INSTA_ITEM", xObj);
    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};
const getWebInstaCalc = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI("POST_API_WEB_INSTA_CALC", xObj);
    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};
const getWebInstaSync = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI("POST_API_WEB_INSTA_SYNC", xObj);
    return vLogin;
  } catch (error) {
    fAlertToast("FAILED", error.response.data.message);
  }
};

export {
  getThirdPartyLogin,
  getWebInstaItems,
  getWebInstaCalc,
  getWebInstaSync,
};
