import React from "react";

//material ui
import { Button } from "@mui/material";

//components
import FilterButton from "./FilterButton/FilterButtons";
import Checklist from "./CheckList/CheckList";

//redux imports
import { setFromDate, setToDate } from "./Slice";
import { useDispatch, useSelector } from "react-redux";

//moment
import { setSubmit } from "../dashboard/slice";
import moment from "moment";

const Filters = ({ onClose }) => {
  const fromDate = useSelector((state) => state?.filterSlice?.from);
  const toDate = useSelector((state) => state?.filterSlice?.to);
  const activeButton = useSelector((state) => state?.filterSlice?.active);
  const screenSize = useSelector((state) => state?.sidebarSlice?.screenSize);
  const formattedFromDate = moment(fromDate).format("YYYY-MM-DD");
  const formattedToDate = moment(toDate).format("YYYY-MM-DD");

  const dispatch = useDispatch();
  onsubmit = () => {
    dispatch(setSubmit(true));

    if (screenSize < 900) {
      onClose();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "85vh",
      }}
    >
      {" "}
      <FilterButton />
      <div style={{ margin: "7px", display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {" "}
          <label style={{ fontSize: "14px", color: "black" }}>Date From</label>
          <input
            type="date"
            onChange={(e) => {
              dispatch(setFromDate(e.target.value));
            }}
            style={{
              border: "none",
              cursor: "pointer",
              color: "darkslategrey",
            }}
            value={formattedFromDate}
            disabled={activeButton === "custom" ? false : true}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <label style={{ fontSize: "14px", color: "black" }}>
            Date To &nbsp;&nbsp;&nbsp;
          </label>
          <input
            type="date"
            style={{
              border: "none",
              cursor: "pointer",
              color: "darkslategrey",
            }}
            value={formattedToDate}
            disabled={activeButton === "custom" ? false : true}
            onChange={(e) => {
              dispatch(setToDate(e.target.value));
            }}
          />
        </div>
      </div>
      <Checklist />
      <Button
        type="submit"
        variant="contained"
        style={{ margin: "8px auto", width: "50%" }}
        onClick={onsubmit}
      >
        Submit
      </Button>
    </div>
  );
};

export default Filters;
