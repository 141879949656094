import { Navigate } from "react-router-dom";
import { selectUserId, selectUserName } from "../pages/login/slice";
import { useSelector } from "react-redux";

function PrivateRoute({ children }) {
  const userId = useSelector((state) => state?.loginSlice?.userId);
  const userName = useSelector((state) => state?.loginSlice?.userName);
  const rememberMe = useSelector((state) => state?.loginSlice?.userName);
  const isLoggedIn = useSelector((state) => state?.loginSlice?.isLoggedIn);
  const isUserAuthenticated = userName && userId && isLoggedIn;

  if (isUserAuthenticated && rememberMe) {
    return children;
  } else {
    return <Navigate to="/login" />;
  }
}

export { PrivateRoute };
