import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import loginSlice from "../pages/login/slice";
import dashboardSlice from "../pages/dashboard/slice";
import filterSlice from "../pages/Filters/Slice";
import sidebarSlice from "../../src/components/layout/sidebar/slice";
import instaIntegrationSlice from "../pages/InstaIntegration/slice";

// add the reducers that need to be persisted
const reducersToPersist = [
  "loginSlice",
  "filterSlice",
  "dashboardSlice",
  "sidebarSlice",
  "instaIntegrationSlice",
];

const persistConfig = {
  key: "misweb",
  storage,
  whitelist: reducersToPersist,
};

const reducers = combineReducers({
  loginSlice: loginSlice,
  dashboardSlice,
  filterSlice,
  sidebarSlice,
  instaIntegrationSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const middleware = [
  //thunk
];

if (process.env.NODE_ENV !== "production") {
  middleware.push(logger);
}

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware, // Note: logger should be the last item in the middleware
});

const persistor = persistStore(store);

export { store, persistor, storage };
